import React from 'react'
import { ArrowRepeat } from 'react-bootstrap-icons'
import { observer } from 'mobx-react-lite'
import { Alert } from 'react-bootstrap'
import { useQuery } from 'react-query'

import OrderList from './OrderList'
import OrderService from '@/telegram/api/OrderService'
import { OrderApiPageResponse } from "@/common/models/api/v0/order.dto"
import useDebounce from '@/common/hooks/useDebounce'

const OrderSection = observer(() => {
    const { data, isFetching, refetch, error } = useQuery<OrderApiPageResponse>('courier-orders',
        () => OrderService.getAll({  }))
    const refetchWithDebounce = useDebounce(refetch, 1000)

    return (
        <div>
            <div className="mt-3">
                <div className="d-flex align-items-center">
                    <span className="heading-3">Назначены мне</span>
                    <span
                        className="ms-auto d-flex align-items-center border border-rounded-1 bg-light p-2"
                        onClick={() => refetch()}
                    >
                        <ArrowRepeat size="25" />
                    </span>
                </div>
                {error && (
                    <Alert variant="danger">
                        <div>
                            <span className="heading-font">Oops. Something went wrong</span>
                        </div>
                        <div>
                            <span>{(error as any).response?.status}</span>
                            <span className="mx-1">{JSON.stringify((error as any).response?.body?.errorMessage)}</span>
                        </div>
                    </Alert>
                )}
            </div>
            {data && (
                <OrderList
                    data={data}
                    isLoading={isFetching}
                    onAnyChange={() => {
                        refetchWithDebounce()
                    }} 
                />
            )}
        </div>
    )
})

export default OrderSection