import { AuthApiResponse } from "../models/api/v0/auth.dto"
import requests from "./apiAgent"

const AuthService = {
    signIn: async (username: string, password: string): Promise<AuthApiResponse> => {
        return requests.post('/auth/signin', { username, password })
    },
    create: async (username: string, password: string, role: string): Promise<void> => {
        return requests.post('/auth/signup', { username, password, role })
    },
    refresh: async (refreshToken: string): Promise<AuthApiResponse> => {
        return requests.post('/auth/refreshToken', { refreshToken })
    },
}

export default AuthService