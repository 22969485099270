import superagent from 'superagent'

import tokenStore from '../storage/tokenStore'
import authStore from '../storage/authStore'

const apiRoot = process.env.REACT_APP_API_ROOT
const AUTH_PATH = '/auth/'

const tokenInjectorPlugin = (request: superagent.Request) => {
    if (tokenStore.isPresent() && !request.url.includes(AUTH_PATH)) {
        request.set('Authorization', `Bearer ${tokenStore.accessToken}`)
    }
}

const handleResponse = (response: superagent.Response) => {
    return response.body
}

const sendRequest = async (outcomingRequest: () => any) => {
    try {
        return await outcomingRequest()
    } catch (error) {
        if (error.response.status !== 401) {
            throw error
        }

        await tokenStore
            .tryRefreshAuthentication()
            .catch(() => authStore.signOut())
        return outcomingRequest()
    }
}

const requests = {
    get: (url: string, queryParams?: any) =>
        sendRequest(() => superagent
            .get(`${apiRoot}${url}`)
            .use(tokenInjectorPlugin)
            .query(queryParams)
            .then(handleResponse)
        ),
    post: (url: string, body?: any) =>
        sendRequest(() => superagent
            .post(`${apiRoot}${url}`)
            .use(tokenInjectorPlugin)
            .send(body)
            .then(handleResponse)
        ),
    put: (url: string, body?: any) =>
        sendRequest(() => superagent
            .put(`${apiRoot}${url}`)
            .use(tokenInjectorPlugin)
            .send(body)
            .then(handleResponse)
        ),
    delete: (url: string) =>
        sendRequest(() => superagent
            .delete(`${apiRoot}${url}`)
            .use(tokenInjectorPlugin)
            .then(handleResponse)
        ),
}

export default requests
