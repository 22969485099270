import { DateRange } from "../models/util"
import requests from "./apiAgent"

const ReportService = {
    getCellLoadReport: async (dateRange: DateRange): Promise<any> => {
        return requests.get('/reports/cell-load', dateRange)
    },

    getDeliveredReport: async (dateRange: DateRange): Promise<void> => {
        return requests.get('/reports/delivered', dateRange)
    },

    getServiceAgreementsReport: async (dateRange: DateRange): Promise<any> => {
        return requests.get('/reports/service-agreements', dateRange)
    },

    getCourierDeliveryDynamicsReport: async (dateRange: DateRange): Promise<any> => {
    	return requests.get('/reports/courier-delivery-dynamics', dateRange)
    },

    getReport: async (reportType: string, dateRange: DateRange): Promise<any> => {
        switch (reportType) {
            case 'cell-load':
                return ReportService.getCellLoadReport(dateRange)
            case 'delivered':
                return ReportService.getDeliveredReport(dateRange)
            case 'service-agreements':
                return ReportService.getServiceAgreementsReport(dateRange)
            case 'courier-delivery-dynamics':
            	return ReportService.getCourierDeliveryDynamicsReport(dateRange)
        }
    }
}

export default ReportService