import React from 'react';
import {Dropdown, Pagination} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

import useOrderSearch from "@/common/hooks/useOrderSearch";
import {OrderApiPageResponse} from "@/common/models/api/v0/order.dto";
import {Statuses} from "@/common/constants/statuses";

interface SelectBarProps {
    data: OrderApiPageResponse,
    controlPage: {
        page: number,
        nextPage: () => void,
        previousPage: () => void
    }
    showAlert: (errorMessage: string) => void
}


const SelectBar = observer(({data, controlPage, showAlert}: SelectBarProps) => {
    const {selectedOrders, setSelectedOrders} = useOrderSearch()
    const navigate = useNavigate()

    const handlePrintStickers = () => {
        let isPrintable = true
        for(const order of selectedOrders){
            if(!Statuses.printAccessibleStatuses.includes(order?.status)) {
                isPrintable = false
                showAlert("Промаркируйте все выбранные доставки для печати наклеек")
                break;
            }
        }
        if(isPrintable) {
            navigate('/order-stickers-print', {state: {
                    orderItems: JSON.stringify(selectedOrders)
                }})
        }
    }

    const resetSelect = () => {
        setSelectedOrders([])
    }
    return (
        <>
            {data && data.orders?.length > 0 && (
                <div className="d-flex justify-content-between align-items-center mt-3">

                    <Pagination size="sm" className={`${controlPage?.page === 1 && 'mx-3'}`}>
                        {controlPage?.page !== 1 &&
                            <Pagination.Prev
                                onClick={controlPage?.previousPage}
                            />
                        }
                        <Pagination.Item active>{controlPage?.page}</Pagination.Item>
                        {(!data.isLast && controlPage?.page < data.totalPages) && (
                            <Pagination.Next
                                onClick={controlPage?.nextPage}
                            />
                        )}
                        <div className="d-flex align-items-center px-3">
                            <span>{data.left} из {data.total}</span>
                        </div>
                    </Pagination>
                    <div className="d-flex flex-column align-items-center">
                        <p className="heading-4 m-0">Выбрано {selectedOrders?.length}</p>
                        <p className="heading-4 m-0 text-primary cursor" onClick={resetSelect}>Сбросить все</p>
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle
                            className="border-rounded-1"
                            disabled={!(selectedOrders.length > 0)}
                        >
                            Печать
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="p-0 mb-1">
                            <Dropdown.Item
                                className="py-2 border-rounded-1 text-center"
                                onClick={handlePrintStickers}
                            >
                                Наклеек
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )}
        </>
    );
})

export default SelectBar;