import React, {useRef, useState} from 'react';
import Select from "react-select";
import {Button} from 'react-bootstrap';

const customSelectStyles = {
    option: (provided) => ({
        ...provided,
        textAlign: 'center'
    }),
    input: (provided) => ({
        ...provided,
        margin: "0 60%"
    })
};

const RouteCellSelect = ({items, disabled, defaultItem, onChange}) => {
    const selectRef = useRef(null)
    const [searchQuery, setSearchQuery] = useState(defaultItem ?? "")
    const options = items.map(buildOptions)
    const [selectedOption, setSelectedOption] = useState(defaultItem ? buildOptions(defaultItem)
        :
        {
            value: "",
            label: ""
        })

    function buildOptions (item) {
        return {
            label: item,
            value: item
        }
    }

    const handleInputChange = (query, {action}) => {
        if(action === "input-change"){
            setSearchQuery(query.toUpperCase())
        }
    }

    const handleOptionChange = (option, {action}) => {
        if(action === "clear") {
            setSelectedOption({
                value: "",
                label: ""
            })
            onChange(null)
            setSearchQuery("")
        }
        if (action === "select-option"){
            setSelectedOption(option)
            onChange(option?.value)
            setSearchQuery(option.label)
        }

        selectRef.current.blur()
        selectRef.current.focus()
        selectRef.current.blur()
    }


    return (
        <>
            {disabled ? (
                    <Button
                        disabled={true}
                        className={"w-100 btn-outline-primary text-dark"}
                    >{defaultItem ?? "Не выбрано"}</Button>
                ) : (
                <Select
                    ref={selectRef}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={customSelectStyles}
                    isDisabled={disabled}
                    value={selectedOption}
                    onChange={handleOptionChange}
                    inputValue={searchQuery}
                    onInputChange={handleInputChange}
                    blurInputOnSelect={true}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    options={options}
                />
            )}
        </>
    )
};



export default RouteCellSelect;