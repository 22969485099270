import React from 'react'
import WebApp from '@twa-dev/sdk'
import { useLocation, useNavigate } from 'react-router-dom'
import { QrCodeScan } from 'react-bootstrap-icons'

import NavItem from './components/NavItem'
import { OrderItemQrValue } from '@/common/models/order'
import { Platform } from '@/common/constants/platform'

const BottomBar = () => {
    const { showScanQrPopup, platform } = WebApp
    const location = useLocation()
    const navigate = useNavigate()

    if (location.pathname.includes('/view')) {
        return
    }

    return (
        <div className="border border-rounded-2 d-inline-flex justify-content-end align-items-center p-2 shadow-sm bg-white" style={{ minHeight: '44px' }}>
            <NavItem
                icon={<QrCodeScan size="35" />}
                onClick={() => {
                    showScanQrPopup(
                        {
                            text: 'Отсканируйте наклейку заказа' 
                        },
                        (value: string) => {
                            const valueClean: string = platform === Platform.ANDROID ? value.replace(/\\/g, '') : value
                            const qrValue = JSON.parse(valueClean) as OrderItemQrValue
                            navigate('/telegram/courier/view', { state: { orderId: qrValue.id } })
                            return true
                        }
                    )
                }}
            />
        </div>
    )
}

export default BottomBar