import React, {memo, useRef, useState} from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'

import { CityService } from '@/common/api/CityService'
import { SelectOption } from '@/common/models/util'

const CityAsyncSelect = ({ onChange = () => {}, name, defaultValue, isLoading = false, readOnly = false }: CityAsyncSelectProps) => {
    const hasMount = useRef(false)
    const [city, setCity] = useState(defaultValue?.value ? defaultValue : null)
    if(hasMount.current === false){
        setCity(defaultValue)
        hasMount.current = true
    }
	const buildSelectOption = item => ({
        value: item?.id,
        label: item?.name,
    })

    const loadOptions = async (search: any, _loadedOptions: any, { page }) => {
        const response = await CityService.getAll({ name: search, page })
        return {
            options: response.cities.map(buildSelectOption),
            hasMore: !response.isLast,
            additional: {
                page: page + 1,
            }
        }
    }

    const onOptionSelect = (option: SelectOption) => {
        setCity(option)
        onChange(option)
    }

    return (
        <AsyncPaginate
            defaultValue={defaultValue}
            placeholder="Город не выбран"
            value={city}
            blurInputOnSelect={true}
            defaultOptions
            name={`city-${name}`}
            isDisabled={readOnly}
            isLoading={isLoading}
            isSearchable={true}
            loadOptions={loadOptions}
            onChange={onOptionSelect}
            additional={{
                page: 0,
            }}
        />
    )
}

interface CityAsyncSelectProps {
    onChange: (option: SelectOption) => void,
    name?: string,
    defaultValue?: SelectOption,
    isLoading?: boolean,
    readOnly?: boolean,
    error?: any,
}

export default memo(CityAsyncSelect)