import DateRangeCalendar from '@/components/controls/calendar/DateRangeCalendar'
import {formatDate, subDays, subMonths } from 'date-fns'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { dateOptions, reportTypeOptions } from './options'
import { DateRange } from '@/common/models/util'
import {formatPeriod, validateDateFormat} from "@/common/utils/utils";

const ReportSelectorBar = ({ onReportTypeChange, onDateChange }: ReportSelectorBarProps) => {
    const [showCustomPeriod, setShowCustomPeriod] = useState<boolean>(false)
    const [customPeriod, setCustomPeriod] = useState({
        startDate: "",
        endDate: "",
    })

    const handlePeriodChange = (period: string) => {
        if (period === 'custom') {
            return setShowCustomPeriod(true)
        } else {
            setShowCustomPeriod(false)
        }

        const today: Date = new Date()
        const todayStr: string = formatDate(new Date(), 'yyyy-MM-dd')

        switch (period) {
            case '*':
                onDateChange(null)
                setCustomPeriod({
                    startDate: "",
                    endDate: "",
                })
                break
            case 'yesterday':
                const dayAgo: string = formatDate(subDays(today, 1), 'yyyy-MM-dd')
                setCustomPeriod({ startDate: dayAgo, endDate: todayStr })
                onDateChange({ startDate: dayAgo, endDate: todayStr })
                break
            case '3day':
                const threeDaysAgo: string = formatDate(subDays(today, 3), 'yyyy-MM-dd')
                setCustomPeriod({ startDate: threeDaysAgo, endDate: todayStr })
                onDateChange({ startDate: threeDaysAgo, endDate: todayStr })
                break
            case 'weekly':
                const weekAgo: string = formatDate(subDays(today, 7), 'yyyy-MM-dd')
                setCustomPeriod({ startDate: weekAgo, endDate: todayStr })
                onDateChange({ startDate: weekAgo, endDate: todayStr })
                break
            case 'monthly':
                const monthAgo: string = formatDate(subMonths(today, 1), 'yyyy-MM-dd')
                setCustomPeriod({ startDate: monthAgo, endDate: todayStr })
                onDateChange({ startDate: monthAgo, endDate: todayStr })
                break
        }
    }

    const handleCustomPeriodSubmit = (range) => {
        setShowCustomPeriod(false)
        setCustomPeriod({ startDate: validateDateFormat(range.fromCreateDate, 'yyyy-MM-dd'), endDate: validateDateFormat(range.toCreateDate, 'yyyy-MM-dd') })
        onDateChange({ startDate: validateDateFormat(range.fromCreateDate, 'yyyy-MM-dd'), endDate: validateDateFormat(range.toCreateDate, 'yyyy-MM-dd') })
    }

    return (
        <div className="d-inline-flex">
            <Form.Select
                className="border-rounded-1 w-100"
                onChange={(e) => onReportTypeChange(e.target.value)}
                style={{ maxWidth: '300px' }}
            >
                {reportTypeOptions.map((o, index) => (
                    <option key={index} value={o.value}>{o.text}</option>
                ))}
            </Form.Select>
            <div>
                <Form.Select
                    className="border-rounded-1 w-100 mx-2"
                    onChange={(e) => handlePeriodChange(e.target.value)}
                    style={{ maxWidth: '300px' }}
                >
                    {dateOptions.map((o, index) => {
                        if(o.value === "custom"
                            && customPeriod?.startDate
                            && customPeriod?.endDate
                        ) {
                            return <option key={index}
                                           value={o.value}
                                           className="d-none"
                            >
                                {formatPeriod(
                                    validateDateFormat(customPeriod?.startDate, "dd-MM-yyyy"),
                                    validateDateFormat(customPeriod?.endDate, "dd-MM-yyyy")
                                )}
                            </option>
                        }
                        return <option key={index} value={o.value}>{o.text}</option>
                    })}
                </Form.Select>
                {showCustomPeriod && (
                    <div className="bg-white border border-rounded-2 shadow position-absolute mt-2 p-2">
                        <DateRangeCalendar
                            onSubmit={handleCustomPeriodSubmit}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

interface ReportSelectorBarProps {
    onDateChange: (dateRange: DateRange) => void,
    onReportTypeChange: (type: string) => void,
}

export default ReportSelectorBar