import React from 'react'
import { Form } from 'react-bootstrap'
import { deliveryTypeOptions, parcelTypeOptions } from './options'
import Input from '@/components/controls/input/Input'

const OrderDetailsForm = ({ disabled = false, initialValues, setFieldValue }: OrderDetailsFormProps) => {

    return (
        <div className="mt-2">
            <div>
                <div className="py-1">
                    <div>
                        <span>Тип доставки</span>
                        <span className="text-danger">*</span>
                    </div>
                </div>
                <Form.Select
                    className="py-2 border-rounded-1"
                    disabled={disabled}
                    defaultValue={initialValues ?
                        initialValues.orderItems[0].deliveryType
                        : deliveryTypeOptions
                            .find((item) => item.value === 'STANDARD')
                            .value
                    }
                    onChange={(e) => {
                        const { value } = e.target
                        if (value === '-') {
                            setFieldValue('orderItems[0].deliveryType', null)   
                        } else {
                            setFieldValue('orderItems[0].deliveryType', value)
                        }
                    }}
                >
                    {deliveryTypeOptions.map((value, index) => (
                        <option key={index} value={value.value}>{value.text}</option>
                    ))}
                </Form.Select>
            </div>
            <div className="my-3">
                <div className="py-1">
                    <div>
                        <span>Тип посылки</span>
                        <span className="text-danger">*</span>
                    </div>
                </div>
                <Form.Select
                    className="py-2"
                    disabled={disabled}
                    defaultValue={initialValues ?
                        initialValues.orderItems[0].parcelType
                        : parcelTypeOptions
                            .find((item) => item.value === 'ENVELOPE')
                            .value
                    }
                    onChange={(e) => {
                        const { value } = e.target
                        if (value === '-') {
                            setFieldValue('orderItems[0].parcelType', null)   
                        } else {
                            setFieldValue('orderItems[0].parcelType', value)
                        }
                    }}
                >
                    {parcelTypeOptions.map((value, index) => (
                        <option key={index} value={value.value}>{value.text}</option>
                    ))}
                </Form.Select>
            </div>
            <Input
                className="py-2"
                id={`orderItems[0].comments`}
                label={"Комментарии к заказу"}
                name={`orderItems[0].comments`}
                as={"textarea"}
                style={{minHeight: "3.5rem"}}
                disabled={disabled}
            />
        </div>
    )
}

interface OrderDetailsFormProps {
    disabled?: boolean,
    initialValues?: any,
    setFieldValue: (field: string, value: any) => Promise<any>,
}

export default OrderDetailsForm