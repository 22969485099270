import {NavLink, useLocation} from 'react-router-dom'
import {Boxes, ClipboardData, FileEarmarkPerson, House, PencilSquare, BoxArrowRight} from 'react-bootstrap-icons'
import React, {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Spinner} from 'react-bootstrap'

import './Sidebar.scss'
import useAuth from '@/common/hooks/useAuth'
import {Roles} from '@/common/constants/roles'
import UserProfile from './components/UserCard'
import NavItem from './components/NavItem'

const Sidebar = observer(() => {
    const { user, signOut, hasRole, isLoading } = useAuth()
    const { pathname } = useLocation()
    const [showNav, setShowNav] = useState(true)

    return (
        <div id="nav-bar">
            <input id="nav-toggle" type="checkbox"
                   readOnly={true}
                   checked={showNav}
            />
            <div id="nav-header"
                 onClick={() => setShowNav(!showNav)}
            >
                <label htmlFor="nav-toggle">
                    <span id="nav-toggle-burger"></span>
                </label>
                <NavLink
                    id="nav-title"
                    to='/'
                >
                    Mercurio
                </NavLink>
                <hr/>
            </div>
            <div id="nav-content">
                <div className="nav-button">
                    <NavItem
                        text="Главная"
                        icon={<House className="fas"/>}
                        currentPath={pathname}
                        toPath="/"
                    />
                </div>
                <div className="nav-button">
                    <NavItem
                        text="Заказы"
                        icon={<Boxes className="fas"/>}
                        currentPath={pathname}
                        toPath="/orders"
                    />
                </div>
                <div className="nav-button">
                    <NavItem
                        text="Курьеры"
                        icon={<FileEarmarkPerson className="fas"/>}
                        currentPath={pathname}
                        toPath="/couriers"
                        show={hasRole(Roles.Manager)}
                    />
                </div>
                <div className="nav-button">
                    <NavItem
                        text="Отчёты"
                        icon={<ClipboardData className="fas"/>}
                        currentPath={pathname}
                        toPath="/reports"
                        show={hasRole(Roles.Manager)}
                    />
                </div>
                <div id="nav-content-highlight"></div>
            </div>

            <div className="nav-footer" id="nav-footer">
                <div
                    className="nav-button d-flex flex-column justify-content-end"
                >
                    <NavItem
                        text="Обновить данные"
                        icon={<PencilSquare className="fas"/>}
                        currentPath={pathname}
                        show={hasRole(Roles.Client)}
                        toPath="/onboard"
                    />
                </div>

                {/*    /!* User *!/*/}

                {(!showNav) ? (
                    <div className="nav-button mt-auto"
                         style={{justifyContent: 'flex-start'}}
                    >
                        {isLoading ? (
                            <div className="d-flex align-items-center justify-content-center p-3">
                                <Spinner/>
                            </div>
                        ) : (
                            <UserProfile user={user} onSignOut={signOut}/>
                        )}
                    </div>
                ) : (
                    <div className="nav-button mt-auto btn-outline-danger">
                        <div className="btn btn-outline-danger"
                             onClick={signOut}
                        >
                            <BoxArrowRight
                                id="logout"
                                className="fas"
                            />
                        </div>

                    </div>
                )}
            </div>
        </div>
    )
})

export default Sidebar
