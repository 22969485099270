import { Outlet } from "react-router-dom"
import { createContext } from "react"

import authStore from "../storage/authStore"

export const AuthContext = createContext({
    authStore,
})

const AuthProvider = () => {
    return (
        <AuthContext.Provider value={{ authStore }}>
            <Outlet />
        </AuthContext.Provider>
    )
}

export default AuthProvider
