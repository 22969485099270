import { makeAutoObservable } from "mobx"

import OrderService from "../api/OrderService"
import { Persistable } from "./persistable"
import { OrderCreateData, OrderItem } from "../models/order"
import { OrderCreateOwnerRole } from "../constants/order"
import { OrderCreateApiResponse } from "../models/api/v0/order.dto"
import { Company } from "../models/company"
import { DeliveryParty } from "../models/deliveryParty"

class OrderCreateFormStore implements Persistable {
    stateIndex: number = 0
    isEditing: boolean = false

    form: OrderCreateData
    orderCreated: OrderCreateApiResponse

    role: OrderCreateOwnerRole
    roleCompany: Company
    roleDeliveryParty: DeliveryParty

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.loadFromLocalStorage()
    }

    updateForm(updatedform: OrderCreateData) {
        this.form = { ...this.form, ...updatedform}
        this.writeToLocalStorage()
    }

    goForward() {
        this.stateIndex = this.stateIndex + 1
        this.writeToLocalStorage()
    }

    goBack() {
        if (this.stateIndex > 0) {
            this.stateIndex = this.stateIndex - 1
            this.writeToLocalStorage()
        }
    }

    setRole(role: OrderCreateOwnerRole) {
        this.role = role
        this.writeToLocalStorage()
    }

    setRoleData(company: Company, deliveryParty: DeliveryParty) {
        this.roleCompany = company
        this.roleDeliveryParty = deliveryParty
        this.writeToLocalStorage()
    }

    setOrderCreated(data: OrderCreateApiResponse) {
        this.orderCreated = data
        this.writeToLocalStorage()
    }

    setFormOrderStatus(status: string) {
        this.form.orderItems[0].status = status
        this.orderCreated.orderItems[0].status = status
        this.writeToLocalStorage()
    }
    
    setIsEditing(isEditing: boolean) {
        this.isEditing = isEditing
        this.writeToLocalStorage()
    } 

    async createOrder(data: OrderCreateData) {
        return OrderService.create(data)
    }

    async updateOrderItem(data: OrderItem, id: number) {
        return OrderService.updateOrderItem(data, id)
    }

    writeToLocalStorage() {
        localStorage.setItem('state-index', JSON.stringify(this.stateIndex))
        localStorage.setItem('order-create-is-editing', JSON.stringify(this.isEditing))

        if (this.role) {
            localStorage.setItem('order-create-role', JSON.stringify(this.role))
            localStorage.setItem('order-create-role-company', JSON.stringify(this.roleCompany))
            localStorage.setItem('order-create-role-delivery-party', JSON.stringify(this.roleDeliveryParty))
        }

        if (this.form) {
            localStorage.setItem('order-create-form', JSON.stringify(this.form))
        }

        if (this.orderCreated) {
            localStorage.setItem('order-create-result', JSON.stringify(this.orderCreated))
        }
    }

    loadFromLocalStorage(): void {
        const stateIndexRaw = localStorage.getItem('state-index')
        const isEditingRaw = localStorage.getItem('order-create-is-editing')

        const roleRaw = localStorage.getItem('order-create-role')
        const roleCompanyRaw = localStorage.getItem('order-create-role-company')
        const roleDeliveryPartyRaw = localStorage.getItem('order-create-role-delivery-party')
        const formRaw = localStorage.getItem('order-create-form')
        const orderCreatedRaw = localStorage.getItem('order-create-result')

        if (stateIndexRaw) {
            this.stateIndex = Number(stateIndexRaw)
        }

        if (isEditingRaw) {
            this.isEditing = JSON.parse(isEditingRaw)
        }

        if (roleRaw) {
            this.role = JSON.parse(roleRaw)
        }

        if (roleCompanyRaw) {
            this.roleCompany = JSON.parse(roleCompanyRaw)
        }

        if (roleDeliveryPartyRaw) {
            this.roleDeliveryParty = JSON.parse(roleDeliveryPartyRaw)
        }

        if (formRaw) {
            this.form = JSON.parse(formRaw)
        }

        if (orderCreatedRaw) {
            this.orderCreated = JSON.parse(orderCreatedRaw)
        }
    }

    resetFormState() {
        this.stateIndex = 0
        this.isEditing = false
    
        this.form = null
        this.orderCreated = null
    
        this.role = null
        this.roleCompany = null
        this.roleDeliveryParty = null

        localStorage.removeItem('state-index')
        localStorage.removeItem('order-create-is-editing')

        localStorage.removeItem('order-create-role')
        localStorage.removeItem('order-create-role-company')
        localStorage.removeItem('order-create-role-delivery-party')
        localStorage.removeItem('order-create-form')
        localStorage.removeItem('order-create-result')
    }
}

const orderCreateFormStore = new OrderCreateFormStore()

export default orderCreateFormStore
