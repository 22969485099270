import React from 'react'

import DefaultButton from '@/components/buttons/DefaultButton'
import actions from './actions'

const OrderActionBar = ({ status, onClick }: OrderActionBarProps) => {

    return (
        <div>
            {actions[status] && actions[status].map((action: any, index: number) => (
                <div key={index} className="my-2" style={{ opacity: '80%' }}>
                    <DefaultButton
                        key={index}
                        onClick={() => onClick(action.event)}
                        variant={action.color}
                        fillSpace
                    >
                        <span>{action.text}</span>
                    </DefaultButton>
                </div>
            ))}
        </div>
    )
}

interface OrderActionBarProps {
    status: string,
    onClick: (event: string) => void,
}

export default OrderActionBar