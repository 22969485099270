import React, {useState} from 'react';
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {OrderSearchQuery} from "@/common/models/order";
import useOrderSearch from "@/common/hooks/useOrderSearch";
import {deliveryStatusOptions} from "@/pages/order/components/options";

const OrdersTabs = ({onChange}) => {
    const { persistedFilterQuery }: OrderSearchQuery = useOrderSearch()
    const {deliveryStatuses} = persistedFilterQuery
    const [ordersViewMode, setOrdersViewMode] = useState(getInitialMode(`${deliveryStatusOptions.find(option => option.value === "*").query}`,
        `${deliveryStatusOptions.find(option => option.value === "DELIVERED").query},${deliveryStatusOptions.find(option => option.value === "NON_DELIVERED").query}`));

    function getInitialMode (activeStatuses, resolvedStatuses) {
        if(deliveryStatuses === activeStatuses)
            return "all"
        else if(deliveryStatuses === resolvedStatuses)
            return "archived"
        else
            return "filtered"
    }
    const handleModeChange = (value) => {
        setOrdersViewMode(value);
        if(value === "all") {
            onChange({
                ...persistedFilterQuery,
                deliveryStatuses: deliveryStatusOptions.find(option => option.value === "*").query
            })
        } else {
            onChange({
                ...persistedFilterQuery,
                deliveryStatuses: `${deliveryStatusOptions.find(option => option.value === "DELIVERED").query},${deliveryStatusOptions.find(option => option.value === "NON_DELIVERED").query}`
            })
        }
    };

    return (
        <ToggleButtonGroup
            type="radio"
            name="options"
            value={ordersViewMode}
            onChange={handleModeChange}
        >
            <ToggleButton className={ordersViewMode !== "all" ? "bg-light" : "bg-primary"} id="all-orders-tbg" value={'all'}>
                <span className={ordersViewMode !== "all" ? "text-dark" : "text-light"}>Все заказы</span>
            </ToggleButton>
            <ToggleButton className={ordersViewMode !== "archived" ? "bg-light" : "bg-primary"} id="archived-orders-tbg"
                          value={'archived'}>
                <span className={ordersViewMode !== "archived" ? "text-dark" : "text-light"}>Архив заказов</span>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default OrdersTabs;