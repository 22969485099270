import React, {useState} from 'react'
import { useQuery } from 'react-query'
import { Pagination } from 'react-bootstrap'
import { PersonAdd } from 'react-bootstrap-icons'

import CourierService from '@/common/api/CourierService'
import usePage from '@/common/hooks/usePage'
import CouriersView from './CouriersView'
import DefaultButton from '@/components/buttons/DefaultButton'
import CouriersForm from "@/pages/courier/components/CouriersForm";
import { CourierApiPageResponse } from '@/common/models/api/v0/courier.dto'

const CouriersSection = () => {
    const { page, nextPage, previousPage, currentPage } = usePage()
    const { data, isFetching, error, refetch } = useQuery<CourierApiPageResponse>(['couriers', currentPage],
        () => CourierService.getAll({ page: currentPage }))

    const [showModal, setShowModal] = useState(false)

    return (
        <div>
            <div className="my-3 mt-5">
                <DefaultButton
                    variant="light"
                    onClick={() => { setShowModal(true)}}
                >
                    <PersonAdd size="20" />
                    <span className="px-1">Создать курьера</span>
                </DefaultButton>
            </div>
            {showModal && (
                <CouriersForm showModal={showModal} setShowModal={setShowModal} onSubmit={refetch} />
            )}

            {data && data.couriers.length > 0 && (
                <Pagination size="sm">
                    {page !== 1 &&
                        <Pagination.Prev
                            onClick={previousPage}
                        />
                    }
                    <Pagination.Item active>{page}</Pagination.Item>
                    {(!data.isLast && page < data.totalPages) && (
                        <Pagination.Next
                            onClick={nextPage}
                        />
                    )}
                    <div className="d-flex align-items-center px-3">
                        <span>{data.left} из {data.total}</span>
                    </div>
                </Pagination>
            )}

            <CouriersView
                data={data ? data.couriers: []}
                isLoading={isFetching}
                error={error}
            />
        </div>
    )
}

export default CouriersSection