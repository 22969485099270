import React, {useEffect, useRef} from 'react';
import {Form} from "react-bootstrap";

interface CheckBoxProps {
    checked: boolean,
    indeterminate?: boolean,
    onChange: () => void,
}

const Checkbox = ({checked = false, indeterminate = false, onChange = () => {}, ...rest}: CheckBoxProps) => {
    const checkboxRef = useRef(null)
    useEffect(() => {
        if(checkboxRef.current){
            checkboxRef.current.indeterminate = indeterminate
        }
    },[indeterminate])
    return (
        <Form.Check
            className={'d-flex justify-content-center'}
            style={{height: '100%'}}
        >
            <Form.Check.Input
                ref={checkboxRef}
                id={"order-select-checkboxIndeterminate"}
                checked={checked}
                className={""}
                onChange={onChange}
                style={{width: '25px',height: '25px'}}
                {...rest}
            />
        </Form.Check>
    );
};

export default Checkbox;