import * as yup from 'yup';
import {RegexPatterns} from "@/common/constants/regexPatterns";
import {checkIfEmpty} from "@/common/utils/utils";

export const getInitialValues = (courierItem: any): any => {
    return {
        firstName: checkIfEmpty(courierItem?.firstName),
        lastName: checkIfEmpty(courierItem?.lastName),
        middleName: checkIfEmpty(courierItem?.middleName),
        phone: checkIfEmpty(courierItem?.phone),
        iin: checkIfEmpty(courierItem?.iin),
        hasCar: courierItem?.hasCar ?? "*",
        contractNumber: checkIfEmpty(courierItem?.contractNumber),
        contractStartDate: courierItem?.contractStartDate ?? "*",
        contractEndDate: courierItem?.contractEndDate ?? "*",
        courierAddress: checkIfEmpty(courierItem?.courierAddress),
        comments: checkIfEmpty(courierItem?.comments),
        birthdate: courierItem?.birthdate ?? "*",
        city: courierItem?.city ?? {
            id: "",
            name: ""
        },
    }
}



const firstName = yup.string()
    .required("Укажите имя")
    .matches(RegexPatterns.names, "Неверный формат имени")
const lastName = yup.string()
    .required("Укажите фамилию")
    .matches(RegexPatterns.names, "Неверный формат фамилии")
const middleName = yup.string()
    .matches(RegexPatterns.names, "Неверный формат отчества")
const phone = yup.string()
    .required("Укажите ваш номер телефона")
    .matches(/^\d{11}$/, "Формат номера: +7 XXX XXX-XX-XX")
    .matches(RegexPatterns.phoneSize, "Укажите ваш номер телефона")
const iin = yup.string()
    .matches(RegexPatterns.iin, "ИИН состоит из 12 цифр")
const hasCar = yup.mixed()

const contractNumber = yup.string()
    .matches(/^\d+$/, "Неправильный формат")
    .max(256, "Слишком длинный номер контракта")
const contractStartDate = yup.string()
const contractEndDate = yup.string()
const birthdate = yup.string()
const courierAddress = yup.string()
    .max(128, "Слишком длинный адрес")
const comments = yup.string()
    .max(256, "Слишком длинный комментарий")
const city = yup.object().shape({
    id: yup.number().required("Город обязателен"),
    name: yup.string().required("Город обязателен")
})

export const CourierModalSchema = {
    custom: yup.object().shape({
        firstName,
        lastName,
        middleName,
        phone,
        iin,
        hasCar,
        contractNumber,
        contractStartDate,
        contractEndDate,
        birthdate,
        courierAddress,
        city,
        comments
    })
}