import React, {FC, useEffect, useRef, useState} from 'react'
import AsyncSelect from "react-select/async"

import {CompanyService} from "@/common/api/CompanyService"
import useDebounce from "@/common/hooks/useDebounce"
import { SuggestCompany, SuggestCompanyPart } from "@/common/models/company"
import {checkIfEmpty, getPointIfPresent} from "@/common/utils/utils";
import { SelectOption, SelectRef } from '@/common/models/util'
import { SuggestCompanyApiResponse } from '@/common/models/api/v0/company.dto'

interface CompanyAsyncSelectProps {
    prevCompany?: SuggestCompanyPart;
    onSelect: (companyData: SuggestCompany) => void;
    onInputChange: (inputValue: string) => void;
}

const CompanyAsyncSelect: FC<CompanyAsyncSelectProps> = ({ onInputChange, prevCompany, onSelect }) => {
    const selectRef = useRef<SelectRef>()
    const [searchQuery, setSearchQuery] = useState(prevCompany?.name ?? '');
    const [selectedOption, setSelectedOption] = useState<SelectOption>(prevCompany ? { value: prevCompany, label: prevCompany?.name } : null)

    const buildSelectOption = (item: SuggestCompany): SelectOption => ({
        value: item,
        label: `${item?.name}, ${item?.addresses[0]?.addressString}`,
    });

    const fetchSuggestions = async (query: string, callback: (data: SelectOption[]) => void) => {
        if (query.length < 4){
            callback([])
            return
        }

        try {
            const response: SuggestCompanyApiResponse = await CompanyService.suggestCompanies(query);
            const fetchedOptions = response?.items || [];
            let allVarCompanies: SelectOption[][] = []
            allVarCompanies = [
                ...fetchedOptions.map(company => {
                        return company?.addresses.map(address => {
                                return buildSelectOption({...company, addresses: [address]})
                            }
                        )
                    }
                )
            ];
            callback(allVarCompanies.flat());
        } catch (error) {
            console.error(error);
        }
    };

    const debouncedFetchSuggestions = useDebounce(fetchSuggestions, 1000);

    const handleOptionSelect = (option) => {
        const companyData: SuggestCompanyPart = {
            name: option?.value?.name,
            contactPhone: option?.value?.contactPhone,
            contactName: option?.value?.contactName,
            city: {
                id: option?.value?.addresses[0].cityId,
                name: option?.value?.addresses[0].cityName,
            },
            address: {
                street: option?.value?.addresses[0]?.street,
                building: option?.value?.addresses[0]?.building,
                apartment: option?.value?.addresses[0]?.apartment,
                addressComment: option?.value?.addresses[0]?.addressComment,
                addressString: option?.value?.addresses[0]?.addressString,
                point: getPointIfPresent(option?.value?.addresses[0]?.point?.lat, option?.value?.addresses[0]?.point?.lon)
            }
        };
        setSelectedOption({
            value: companyData,
            label: checkIfEmpty(companyData.name)
        })
        onSelect(companyData);
        setSearchQuery(companyData.name)
        onInputChange(companyData.name)
    };

    const handleChangeInput = (value: string, { action }) => {

        if (action === 'input-change') {
            setSearchQuery(value)
            onInputChange(searchQuery)
        }
    };

    const handleSelectOnBlur =() => {
        onInputChange(searchQuery)
    }

    useEffect(() => {
        if (selectRef.current) {
            selectRef.current.focus();
            selectRef.current.blur();
        }
    }, [selectedOption])

    return (
        <div>
            <AsyncSelect
                ref={selectRef}
                cacheOptions
                loadOptions={debouncedFetchSuggestions}
                placeholder={"Выберите компанию"}
                value={selectedOption}
                onChange={handleOptionSelect}
                inputValue={searchQuery}
                onInputChange={handleChangeInput}
                onBlur={handleSelectOnBlur}
                blurInputOnSelect
                noOptionsMessage={() => {
                    if (selectedOption?.label?.length > 0) {
                        return (!selectedOption.label 
                            && `Компания ${searchQuery} не найдена`) 
                    }

                    return "Введите компанию"
                }}
            />
            {searchQuery?.trim().length > 0 && searchQuery?.trim().length < 4 &&
                <span className="text-danger">Введите как минимум 3 символа</span>}
        </div>
    );
};




export default CompanyAsyncSelect