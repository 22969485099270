import {createContext} from "react";
import {Outlet} from "react-router-dom";

import orderStore from "../storage/orderStore";

export const OrderContext = createContext({
    orderStore,
})

const OrderProvider = () => {
    return (
        <OrderContext.Provider value={{orderStore}}>
            <Outlet/>
        </OrderContext.Provider>
    )
}

export default OrderProvider