import React, {FC, useState} from 'react'
import { Badge, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import WebApp from '@twa-dev/sdk'

import OrderActionBar from './OrderActionBar'
import {convertToTimeZone, formatNumberMasked, getDeliveryPartyCity} from '@/common/utils/utils'
import OrderService from '@/telegram/api/OrderService'
import { Formats } from '@/common/constants/dateFormat'
import { Platform } from '@/common/constants/platform'
import { OrderItem } from '@/common/models/order'

const OrderActionCard = ({ data }: OrderActionCardProps) => {
    const { t } = useTranslation()

    const [currentStatus, setCurrentStatus] = useState<string>(data.status)
    const [error, setError] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { showPopup } = WebApp

    const handleStatusChange = async (event: string) => {
        setIsLoading(true)
        OrderService.changeStatus(data.id, event)
            .then((newStatus) => setCurrentStatus(newStatus))
            .catch((error) => setError(error))
            .finally(() => setIsLoading(false))
    }

    return (
        <div>
            {error && JSON.stringify(error)}
            {isLoading && (
                <Spinner />
            )}
            <div className="d-flex mb-4">
                <div>
                    <span className="d-block heading-3">Заказ №{data.id}</span>
                    <span className="text-muted">
                        {t(`orderStatus.${currentStatus}`, {
                            from_city: getDeliveryPartyCity(data.deliverySender),
                            to_city: getDeliveryPartyCity(data.deliveryReceiver),
                        })}
                    </span>
                </div>
            </div>
            <div className="position-relative bg-white border border-rounded-2 p-3">
                <div className="position-absolute" style={{ top: '-12px' }}>
                    <Badge className="border" pill>
                        <span className="ms-auto heading-badge">
                            {convertToTimeZone(data.createDate, false, Formats.DATE_DMY_TIME)}
                        </span>
                    </Badge>
                </div>
                <div>
                    <div className="mb-2">
                        <OrderProperty
                            text="Отправитель" 
                            value={data?.deliverySender?.company?.name} 
                        />
                    </div>
                    <div className="my-2">
                        <OrderProperty 
                            text="Адрес отправителя"
                            value={data?.deliverySender?.address?.addressString}
                        />
                    </div>
                    <div className="my-2">
                        <div>
                            <span className="d-block heading-font">{data?.deliverySender?.contactName}</span>
                            <PhoneLink phone={data?.deliverySender?.phone}/>
                        </div>
                    </div>
                    <div className="my-2">
                        <OrderProperty 
                            text="Получатель" 
                            value={data?.deliveryReceiver?.company?.name} 
                        />
                    </div>
                    <div className="my-2">
                        <OrderProperty 
                            text="Адрес получателя" 
                            value={data?.deliveryReceiver?.address?.addressString} 
                        />
                    </div>
                    <div className="my-2">
                        <div>
                            <span className="d-block heading-font">{data?.deliveryReceiver?.contactName}</span>
                            <PhoneLink phone={data?.deliveryReceiver?.phone}/>
                        </div>
                    </div>
                    <div className="mt-2">
                        <OrderProperty 
                            optional
                            text="Комментарии" 
                            value={data?.comments} 
                        />
                    </div>
                </div>
            </div>

            <div className="mt-4">
                <OrderActionBar
                    status={currentStatus}
                    onClick={(event) => {
                        showPopup(
                            {
                                message: `Подтвердите действие "${event}"`,
                                buttons: [{ id: event, text: 'Подтвердить', type: 'default' }]
                            },
                            (value) => handleStatusChange(value)
                        )
                    }} 
                />
            </div>
        </div>
    )
}

const OrderProperty = ({ text, value, optional = false }: OrderPropertyProps) => {

    if (optional && !value) {
        return
    }

    return (
        <div>
            <span className="d-block heading-font">{text}</span>
            <span>{value}</span>
        </div>
    )
}

const PhoneLink: FC<PhoneLinkProps> = ({ phone, className = '' }) => {
    const { platform } = WebApp
    const maskedPhone: string = formatNumberMasked(phone)
    const phoneRef: string = (platform === Platform.ANDROID ? '//' : '') + maskedPhone

    const handlePhoneClick = (e: any) => {
        e.preventDefault()
        window.open(`tel:${phoneRef}`)
    }

    return (
        <a 
            className={className}
            href={`tel:${phoneRef}`}
            onClick={handlePhoneClick}
        >
            {maskedPhone}
        </a>
    )
}

interface PhoneLinkProps {
    phone: string,
    className?: string
}

interface OrderActionCardProps {
    data: OrderItem,
}

interface OrderPropertyProps {
    text: string,
    value: any,
    optional?: boolean,
}

export default OrderActionCard