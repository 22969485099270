import React from 'react'
import { Container } from 'react-bootstrap'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { BackButton } from '@twa-dev/sdk/react'

import './layout.css'
import BottomBar from './components/bottombar/BottomBar'

const TelegramLayout = () => {
    const rootPath = '/telegram/courier'
    const { pathname } = useLocation()
    const navigate = useNavigate()

    return (
        <Container fluid className="position-relative p-3" style={{ minHeight: '100vh', wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {pathname !== rootPath && (
                <BackButton
                    onClick={() => navigate(-1)}
                />
            )}
            <Outlet />
            <div className="position-fixed d-flex justify-content-center p-3 bottom-0 start-0 end-0">
                <BottomBar />
            </div>
        </Container>
    )
}

export default TelegramLayout