import { ru } from 'date-fns/locale';
import {parseISO, addMinutes, subMinutes, formatDate, parse, format, isValid} from 'date-fns';

import { OrderItem, OrderSearchFilter, OrderSearchQuery } from '../models/order'
import {Formats, possibleDateFormats} from "@/common/constants/dateFormat";
import { DeliveryParty } from '../models/deliveryParty'
import { SelectOption } from '../models/util'
import {Courier} from "@/common/models/courier";

export const buildDeliveryPartyString = (deliveryParty: DeliveryParty): string => {
    let deliveryPartyString = ""

    if (deliveryParty?.isCompany) {
        deliveryPartyString = appendString(deliveryPartyString, deliveryParty?.company?.name)
    }

    deliveryPartyString = appendString(deliveryPartyString, deliveryParty?.address?.addressString)

    return deliveryPartyString
}

export const getDeliveryPartyCity = (deliveryParty: DeliveryParty): string => {
    return deliveryParty?.address?.city?.name
}

export const buildOrderItemUpdateDto = (values: OrderItem, deliveryType: SelectOption, parcelType: SelectOption, deliveryReceiver: DeliveryParty, courier: Courier = null): OrderItem => {
    return {
        id: values?.id,
        createDate: values?.createDate,
        estimatedDeliveryDate: convertToTimeZone(values?.estimatedDeliveryDate, true, Formats.DATE_TIMESTAMP),
        deliveryType: deliveryType?.value,
        parcelType: parcelType?.value,
        comments: values?.comments,
        cell: values?.cell,
        route: values?.route,
        calculatedCost: values?.calculatedCost,
        courier: courier ?? (values?.courier
        	? {
        				id: values?.courier.id,
                        firstName: values.courier.firstName,
                        lastName: values.courier.lastName,
                        middleName: values.courier.middleName,
                        birthdate: values.courier.birthdate,
                        isActive: values.courier.isActive,
                        hasCar: values.courier.hasCar,
                        contractNumber: values.courier.contractNumber,
                        contractStartDate: values.courier.contractStartDate,
                        contractEndDate: values.courier.contractEndDate,
                        courierAddress: values.courier.courierAddress,
                        comments: values.courier.comments,
            }
            : null),
        deliveryReceiver: {
            partyId: deliveryReceiver?.partyId,
            address: deliveryReceiver?.address,
            phone: deliveryReceiver?.phone,
            contactName: deliveryReceiver?.contactName,
            company: {
                ...deliveryReceiver?.company,
                bin: null,
            },
        }
    }
}

export const appendString = (baseString: string, appendString: string): string => {
    if (!appendString) {
        return baseString
    } else {
        return baseString ? baseString + ', ' + appendString : appendString
    }
}

export function buildFetchOrdersQuery(searchQuery: string, searchType: string, filterQuery: OrderSearchFilter, page: number): OrderSearchQuery {
    return searchQuery.trim() ? ({[searchType]: searchQuery, ...filterQuery, page}) 
        : ({...filterQuery, page})
}

export const checkIfEmpty = (field: string | null) => field ?? ''

export const formatNumber = (number: string | null, withCountryCode = false): string => {
    if(!number)
        return ""
    let formatNumber = number.replace(/[^\d+]|(?!^)\+/g, '')
    if(withCountryCode) {
        if (formatNumber.slice(0, 1) === "8" && formatNumber.length === 11)
            return "+7" + formatNumber.slice(1)
        if(formatNumber.slice(0, 1)=== "7" && formatNumber.length === 11)
            return "+7" + formatNumber.slice(1)
        if(formatNumber.length === 10)
            return "+7" + formatNumber
    } else {
        if(formatNumber.slice(0, 2) === "+7" && formatNumber.length === 12)
            return "8" + formatNumber.slice(2)
        if(formatNumber.slice(0, 2) === "+7" && formatNumber.length === 11)
            return "87" + formatNumber.slice(2)
        if(formatNumber.slice(0, 1) === "7" && formatNumber.length === 11)
            return "8" + formatNumber.slice(1)
    }
    return formatNumber
}

export const formatNumberMasked = (number: string) => {
    const cleaned = ('' + number).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
    if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
    }
    return null
}

function detectDateFormat(dateString: string): string | null {
    for (let format of possibleDateFormats) {
        const parsedDate = parse(dateString, format, new Date());
        if (isValid(parsedDate)) {
            return format;
        }
    }

    return null; // Return null if no format matches
}

export function validateDateFormat(date: Date | string, formatDate: string): string | null {
    if (date === null || date === undefined) {
        return null;
    }

    let dateString: string;
    if (date instanceof Date) {
        dateString = format(date, formatDate);
    } else {
        dateString = date;
    }

    const detectedFormat = detectDateFormat(dateString);
    if (detectedFormat !== formatDate) {
        const parsedDate = parse(dateString, detectedFormat, new Date());
        if (!isValid(parsedDate)) {
            throw new Error('Invalid date format');
        }
        return format(parsedDate, formatDate);
    }

    return dateString;
}

export function formatPeriod(start: string, end: string): string {
    const startDate = parse(start, Formats.DATE_DMY, new Date())
    const endDate = parse(end, Formats.DATE_DMY, new Date())
    // Форматы для различных случаев
    const sameMonthYearFormat = 'dd';
    const differentYearFormat = 'dd MMM yyyy';

    // Получаем части даты
    const startDay = format(startDate, sameMonthYearFormat, { locale: ru });
    const endDay = format(endDate, sameMonthYearFormat, { locale: ru });
    const startMonth = format(startDate, 'MMM', { locale: ru });
    const endMonth = format(endDate, 'MMM', { locale: ru });
    const startYear = format(startDate, 'yyyy');
    const endYear = format(endDate, 'yyyy');

    if (startYear === endYear) {
        if (startMonth === endMonth) {
            return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
        } else {
            return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
        }
    } else {
        const startFull = format(startDate, differentYearFormat, { locale: ru });
        const endFull = format(endDate, differentYearFormat, { locale: ru });
        return `${startFull} - ${endFull}`;
    }
}

// Function to get the offset between the target time zone and UTC in minutes
function getTimeZoneOffset(targetTimeZone: string): number {
    const date = new Date();
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone: targetTimeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 60000; // Offset in minutes
}

// Function to convert datetime-local input value to a different time zone or revert to UTC
export function convertToTimeZone(datetimeLocalString: string, revert: boolean = false, format = Formats.DATE_DMY_TIME): string {
    const targetTimeZone = 'Asia/Oral';

    if (!datetimeLocalString || datetimeLocalString === '') return;

    const date = parseISO(datetimeLocalString);

    const offset = getTimeZoneOffset(targetTimeZone);

    let adjustedDate;

    if (revert) {
        adjustedDate = offset > 0 ? subMinutes(date, offset) : addMinutes(date, -offset);
    } else {
        adjustedDate = offset > 0 ? addMinutes(date, offset) : subMinutes(date, -offset);
    }

    return formatDate(adjustedDate, format);
}

export const getPointIfPresent = (lat, lon) => {
        return (lat && lon) ? ({lat,lon}) : null
}