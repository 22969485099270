import { makeAutoObservable } from "mobx"
import { jwtDecode } from "jwt-decode"

import { Persistable } from "./persistable"
import { AuthPrincipal } from "../models/auth"
import { AuthApiResponse } from "../models/api/v0/auth.dto"
import AuthService from "../api/AuthService"

class TokenStore implements Persistable {

    accessToken: string = null
    refreshToken: string = null

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.loadFromLocalStorage()
    }

    setAuthentication(auth: AuthApiResponse) {
        this.accessToken = auth.accessToken
        this.refreshToken = auth.refreshToken
        this.writeToLocalStorage()
    }

    setAccessToken(accessToken: string) {
        this.accessToken = accessToken
        this.writeToLocalStorage()
    }

    setRefreshToken(refreshToken: string) {
        this.refreshToken = refreshToken
        this.writeToLocalStorage()
    }

    isPresent(): boolean {
        return this.accessToken && this.accessToken !== ''
    }

    getDecodedPrincipal(): AuthPrincipal {
        return jwtDecode(this.accessToken)
    }

    clearStore() {
        this.accessToken = null
        this.refreshToken = null
        this.removeFromLocalStorage()
    }

    writeToLocalStorage() {
        if (this.accessToken && this.refreshToken) {
            localStorage.setItem('access_token', this.accessToken)
            localStorage.setItem('refresh_token', this.refreshToken)
        }
    }

    removeFromLocalStorage() {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
    }

    loadFromLocalStorage() {
        const accessToken = localStorage.getItem('access_token')
        const refreshToken = localStorage.getItem('refresh_token')

        if (accessToken && refreshToken) {
            this.setAccessToken(accessToken)
            this.setRefreshToken(refreshToken)
        }
    }

    async tryRefreshAuthentication() {
        try {
            const response = await AuthService.refresh(this.refreshToken)
            this.setAuthentication(response)
            this.writeToLocalStorage()
        } catch (error) {
            console.error('Failed to refresh authentication: ', error)
            throw error
        }
    }
}

const tokenStore = new TokenStore()

export default tokenStore
