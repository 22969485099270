import React from "react"
import Spacer from "@/components/Spacer"

export const StepActionBottomBar = ({ children }) => {
    return (
        <div className="d-flex ms-auto">
            <span className="d-flex ms-auto">
                {React.Children.toArray(children).map((child, index, arr) => (
                    <React.Fragment key={index}>
                        {child}
                        {index < arr.length - 1 && <Spacer width="10px" />}
                    </React.Fragment>
                ))}
            </span>
        </div>
    )
}