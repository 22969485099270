import React from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'

import OrderActionCard from './components/OrderActionCard'
import { OrderItem } from '@/common/models/order'
import OrderService from '@/telegram/api/OrderService'
import DefaultButton from '@/components/buttons/DefaultButton'

const TelegramOrderActionPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const orderId = location.state?.orderId

    const { data, isLoading, error } = useQuery<OrderItem>('order-courier', () => OrderService.getById(orderId), { cacheTime: 0, staleTime: 0 })

    if (isLoading) {
        return (
            <div className="d-flex vh-100 align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <Spinner/>
                    <span className="my-2">Получение данных...</span>
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className="d-flex vh-100 align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <span className="my-2 text-danger">
                        {(error as any)?.response?.body?.errorMessage ?
                            (error as any)?.response?.body?.errorMessage
                            : JSON.stringify(error)
                        }
                    </span>
                    <DefaultButton variant="light" onClick={() => navigate(-1)} bordered>
                        Вернуться назад
                    </DefaultButton>
                </div>
            </div>
        )
    }

    return (
        <div>
            {data && (
                <OrderActionCard data={data} />
            )}
        </div>
    )
}

export default TelegramOrderActionPage
