import React from 'react'

const UserSection = ({ user }: UserSectionProps) => {

    if (!user) {
        return
    }

    return (
        <div className="py-2">
            <span className="heading-font">Привет, {user && user.first_name} 👋</span>
        </div>
    )
}

interface UserSectionProps {
    user?: any,
}

export default UserSection