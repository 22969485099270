const actions = {
    'PICKUP_COURIER_ASSIGNED': [
        {
            text: 'В путь',
            event: 'MOVE_TO_WAREHOUSE',
            color: 'primary',
        },
    ],
    'ON_WAY_TO_WAREHOUSE': [
        {
            text: 'Прибыл на склад',
            event: 'WAREHOUSE_ARRIVED',
            color: 'primary'
        }
    ],
    'DELIVERY_COURIER_ASSIGNED': [
        {
            text: 'В путь',
            event: 'DELIVER_TO_RECEIVER',
            color: 'primary',
        },
    ],
    'DELIVERY_TO_DEPARTURE': [
        {
            text: 'Доставлен в пункт отправки',
            event: 'COURIER_CONFIRM_DEPARTURE_DELIVERY',
            color: 'primary',
        }
    ],
    'DELIVERY_IN_PROGRESS': [
        {
            text: 'Не успел',
            event: 'UNABLE_TO_DELIVER',
            color: 'danger',
        },
        {
            text: 'Неверный адрес',
            event: 'UNABLE_TO_DELIVER',
            color: 'danger',
        },
        {
            text: 'Клиента нет на месте',
            event: 'UNABLE_TO_DELIVER',
            color: 'danger',
        },
        {
            text: 'Доставлено',
            event: 'COURIER_CONFIRM_DELIVERY',
            color: 'success',
        },
    ],
    'PICKUP_COURIER_ARRIVAL_ASSIGNED': [
        {
            text: 'Забор получен',
            event: 'COURIER_PICKUP_ARRIVAL',
            primary: 'primary',
        }
    ],
    'DELIVERY_COURIER_ARRIVAL_ASSIGNED': [
        {
            text: 'Неверный адрес',
            event: 'UNABLE_TO_DELIVER',
            color: 'danger',
        },
        {
            text: 'Клиента нет на месте',
            event: 'UNABLE_TO_DELIVER',
            color: 'danger',
        },
        {
            text: 'Доставлен курьером',
            event: 'COURIER_CONFIRM_DELIVERY',
            primary: 'primary',
        }
    ]
}

export default actions