import React from 'react';
import OnboardForm from "@/pages/onboard/components/Form/OnboardForm";

const OnboardViewPage = () => {
    return (
        <div className="d-flex w-100 flex-column justify-content-start">
            <p className="heading-2">Введите данные компании</p>
            <p className="heading-4">Перед тем, как приступить к созданию заказа, пожалуйста, введите данные о Вашей компании. Это поможет ускорить дальнейшие шаги по созданию заказа.</p>
            <OnboardForm/>
        </div>
    );
};

export default OnboardViewPage;