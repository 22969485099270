import React from 'react'
import { observer } from 'mobx-react-lite'
import { ProgressBar } from 'react-bootstrap'

import useOrderCreateForm from '@/common/hooks/useOrderCreateForm'
import Heading from '@/components/typography/Heading'

const OrderCreateStatus = observer(() => {
    const { stateIndex, isEditing } = useOrderCreateForm()

    const stateLabels = [
        {
            text: 'Выбор роли',
            progressVal: 33,
        },
        {
            text: 'Заполнение данных заказа',
            progressVal: 66,
        },
        {
            text: 'Заказ создан',
            progressVal: 100,
        }
    ]

    const editStateLabels = [
        {
            text: 'Редактирование заказа',
            progressVal: 50,
        },
        {
            text: 'Заказ обновлён',
            progressVal: 100,
        }
    ]

    return (
        <div>
            <div>
                {/* <Heading color="primary" size="4" icon={<ClipboardPlusFill size="20" />}> */}
                <div className="d-flex justify-content-between">
                    {isEditing ? (
                        editStateLabels.map((value, index) => (
                            <Heading key={index} color={index === stateIndex - 1 ? 'primary' : 'dark'} size="4">
                                {value.text}
                            </Heading>
                        ))
                    ) : (
                        stateLabels.map((value, index) => (
                            <Heading key={index} color={index === stateIndex ? 'primary' : 'dark'} size="4">
                                {value.text}
                            </Heading>
                        ))
                    )}
                </div>
                <div className="py-3">
                    {isEditing ? (
                        <ProgressBar now={editStateLabels[stateIndex - 1].progressVal} />
                    ) : (
                        <ProgressBar now={stateLabels[stateIndex].progressVal} />
                    )}
                </div>
            </div>
        </div>
    )
})

export default OrderCreateStatus