import {makeAutoObservable} from "mobx";

import {Persistable} from "../storage/persistable";
import {SortOption} from "@/common/models/util";
import {OrderItem, OrderSearchFilter} from "@/common/models/order";

class OrderStore implements Persistable{
    persistedSortOptions: SortOption[] = []
    persistedFilterQuery: OrderSearchFilter | {} = {}
    // TODO: Make dto to take only needed fields after implementing all functions
    selectedOrders: OrderItem[] = []

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
        this.loadFromLocalStorage()
    }

    setSelectedOrders(orders) {
        this.selectedOrders = orders
        localStorage.setItem('selectedOrders', JSON.stringify(this.selectedOrders))
    }

    persistSortOptions(options) {
        this.persistedSortOptions = options
        localStorage.setItem('sortOptions', JSON.stringify(this.persistedSortOptions))
    }

    persistFilterQuery(options) {
        this.persistedFilterQuery = options
        localStorage.setItem('filterQuery', JSON.stringify(this.persistedFilterQuery))
    }



    loadFromLocalStorage(): void {
        const sortOptions = localStorage.getItem('sortOptions')
        const filterQuery = localStorage.getItem('filterQuery')
        const selectedOrders = localStorage.getItem('selectedOrders')

        if (sortOptions) {
            this.persistedSortOptions = JSON.parse(sortOptions)
        }
        if(filterQuery){
            this.persistedFilterQuery = JSON.parse(filterQuery)
        }
        if(selectedOrders){
            this.selectedOrders = JSON.parse(selectedOrders)
        }
    }
}

const orderStore = new OrderStore()

export default orderStore