import React, { useState } from 'react'
import { Navigate } from "react-router-dom";
import { observer } from 'mobx-react-lite'
import {Container, Button, Nav, Row, Alert, Spinner, Col} from 'react-bootstrap';
import {Formik, Form} from "formik";

import useAuth from '@/common/hooks/useAuth'
import Input from "@/components/controls/input/Input";
import {AuthValidationSchema, initialValues} from "@/pages/signup/SignupValidation";
import {checkIfEmpty} from "@/common/utils/utils";

const SignUpPage: React.FC = observer(() => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [error, setError] = useState('');

	const auth = useAuth()

	if (auth.isAuthenticated()) return <Navigate to="/" />;

	const handleRegistration = async (values) => {
		try {
			setIsLoading(true);
			await auth.create(values.userName, values.password);
			return;
		} catch (error) {
			handleRegistrationError(error);
		} finally {
			setIsLoading(false)
		}

	}

	const handleRegistrationError = (error) => {
		if(error.response.status === 409
			&& error.response.body.errorCode === "USER_ALREADY_EXISTS") {
			setError('Пользователь уже существует');
		} else {
			console.error("Can't handle it right now...");
		}
	}

	if (isLoading)
		return <Container className="vh-100 d-flex justify-content-center align-items-center">
			<Spinner animation={"border"}/>
		</Container>

	return (
        <Container className="vh-100 d-flex flex-column ">
			<Row className="vh-100  d-flex align-items-center justify-content-center">
        		<div style={{ display: 'block',
                	width: 700,
                    padding: 30 }}>
					<Formik initialValues={initialValues}
							onSubmit={(values) => handleRegistration(values)}
							validationSchema={AuthValidationSchema.custom}
					>
						{({values, handleSubmit}) => (
							<Form onSubmit={handleSubmit}>
								<Input
									id={"userName"}
									label={"Имя пользователя:"}
									name={"userName"}
									value={checkIfEmpty(values.userName)}
									placeholder={"Введите email или имя пользователя"}
								/>
								<Input
									id={"password"}
									label={"Введите пароль:"}
									name={"password"}
									placeholder={"Введите пароль"}
									type={values.passwordCheckbox ? "text" : "password"}
								/>
								<Input
									id={"confirmPassword"}
									label={"Подтвердите пароль:"}
									name={"confirmPassword"}
									placeholder={"Введите пароль повторно"}
									type={values.passwordCheckbox ? "text" : "password"}
								/>
								<Row className="d-flex flex-row justify-content-center align-items-center w-50">
									<Col className="d-flex justify-content-end align-items-center">
										<label>Показать пароль</label>
									</Col>
									<Col className="d-flex justify-content-start align-items-center">
										<Input
											id={"passwordCheckbox"}
											label={""}
											name={"passwordCheckbox"}
											type={"checkbox"}
											style={{transform: "scale(1.5)"}}
										/>

									</Col>
								</Row>
								{error && <Alert variant="danger">{error}</Alert>}
								<Container>
									<Row>
										<Button className='mt-3'
												variant="primary"
												type="submit">
											Зарегистрироваться
										</Button>
										<Nav>
											<Nav.Item>
												<Nav.Link href="/signin">Войти в систему</Nav.Link>
											</Nav.Item>
										</Nav>
									</Row>
								</Container>
							</Form>
						)}
					</Formik>
                </div>
            </Row>
        </Container>
    )
})

export default SignUpPage
