import React from 'react'
import { observer } from 'mobx-react-lite'

import OrderCreateStatus from './OrderCreateStatus'
import useOrderCreateForm from '@/common/hooks/useOrderCreateForm'
import { GetOrderDetailsStep } from './steps/GetOrderDetailsStep'
import GetOrderOwnerRoleStep from './steps/GetOrderOwnerRoleStep'
import GetOrderCreateResultStep from './steps/GetOrderCreateResultStep'

const OrderCreateFormBody = observer(() => {
    const { stateIndex } = useOrderCreateForm()

    const forms = [
        <GetOrderOwnerRoleStep />,
        <GetOrderDetailsStep />,
        <GetOrderCreateResultStep />,
    ]

    return (
        <div>
            <div className="pb-3">
                <OrderCreateStatus />
            </div>
            <div>
                {forms[stateIndex]}
            </div>
        </div>
    )
})

export default OrderCreateFormBody