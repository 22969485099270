import React, { InputHTMLAttributes, memo, useEffect, useState } from 'react'
import { InputMask, Replacement } from '@react-input/mask'
import { Field, useField } from "formik"

import './FormikInput.css'
import { checkIfEmpty, formatNumberMasked } from '@/common/utils/utils'

/**
 * TODO: Implement parametrization of masking types: phone, date (?), credit card (?) and etc. 
 */
const InputPhoneMasked = (
    { label, id, name, className, required = false, onMaskChange, ...rest }: InputHTMLAttributes<HTMLInputElement> & InputPhoneMaskedProps
) => {
    const phoneMask: any = { mask: '+_ (___) ___-__-__', replacement: { _: /\d/ } }
    const [field, meta, helpers] = useField(name)
    const { setValue } = helpers
    const [displayValue, setDisplayValue] = useState<string>(field.value)

    const normalize = (value: string) => {
        if (!value.startsWith('7') && value.length === 11) {
            return '7' + value.substring(1)
        }

        return value
    }

    useEffect(() => {
        const normalizedInitialValue = normalize(field.value)

        if (field.value !== normalizedInitialValue) {
            setDisplayValue(normalizedInitialValue)
            setValue(normalizedInitialValue)
            return onMaskChange(normalizedInitialValue.replace(/\D/g, ''))
        }

        setDisplayValue(normalizedInitialValue)
    }, [field.value, setValue, onMaskChange])

    return (
        <div className={`d-flex flex-column ${checkIfEmpty(className)}`}>
            <label htmlFor="id" className="formik-label">
                {label}
                {required && <span className="text-danger">*</span>}
            </label>
            <Field
                id={id}
                name={name}
                className="formik-input"
                mask={phoneMask.mask}
                replacement={phoneMask.replacement}
                value={formatNumberMasked(displayValue)}
                onMaskChange={(value: string) => {
                    const normalizedValue = normalize(value)
                    setDisplayValue(normalizedValue)
                    setValue(normalizedValue)
                    onMaskChange(normalizedValue)
                }}
                component={InputMaskWrapped}
                {...rest}
            />
            {meta.touched && meta.error &&
                <span className="formik-error">{meta.error}</span>}
        </div>
    )
}

const InputMaskWrapped = (
    { replacement, mask, onMaskChange, ...rest }: InputHTMLAttributes<HTMLInputElement> & InputMaskWrappedProps
) => {
    return (
        <InputMask
            mask={mask}
            replacement={replacement}
            showMask={true}
            {...rest}
            onMask={(e) => onMaskChange(e.detail.input)}
        /> 
    )
}

interface InputPhoneMaskedProps {
    label: string,
    className?: string,
    required?: boolean
    onMaskChange: (value: string) => void,
}

interface InputMaskWrappedProps {
    mask: string
    replacement: Replacement,
    onMaskChange: (value: string) => void,
}

export default memo(InputPhoneMasked)