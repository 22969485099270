import React from 'react';
import {observer} from "mobx-react-lite";
import {XLg} from 'react-bootstrap-icons'
import {Badge} from "react-bootstrap";

import useOrderSearch from "@/common/hooks/useOrderSearch";
import {sortFieldOptions} from "@/pages/order/components/options";

const OrderItemSort = observer(() => {
    const {persistedSortOptions, persistSortOptions} = useOrderSearch()
    const handleResetSort = () => {
        persistSortOptions([])
    }
    return (
        <>
            {
                persistedSortOptions?.length > 0 && (
                    <div className="w-50 d-flex mb-3">
                        <Badge className="badge badge-pill d-flex justify-content-between align-items-center heading-5 cursor py-2 border-rounded-1" onClick={handleResetSort}><XLg className="mx-1"/> Сортировать по {sortFieldOptions[persistedSortOptions[0]?.key]}</Badge>
                    </div>
                )
            }
        </>
    );
})

export default OrderItemSort;