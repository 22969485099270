import { createContext } from "react"
import orderCreateFormStore from "../storage/orderCreateFormStore"

export const OrderCreateFormContext = createContext({
    orderCreateFormStore,
})

const OrderCreateFormProvider = ({ children }) => {
    return (
        <OrderCreateFormContext.Provider value={{ orderCreateFormStore }}>
            {children}
        </OrderCreateFormContext.Provider>
    )
}

export default OrderCreateFormProvider 
