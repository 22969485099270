import { useState } from "react"
import { Form } from "react-bootstrap"
import { Check } from "react-bootstrap-icons"
import { Calendar } from "react-calendar"

import 'react-calendar/dist/Calendar.css'
import './customCalendarStyles.css'
import DefaultButton from "../../buttons/DefaultButton"
import { DateRange } from "@common/models/util"
import {Formats} from "@/common/constants/dateFormat";
import {checkIfEmpty, validateDateFormat} from "@/common/utils/utils";
import {OrderSearchQuery} from "@/common/models/order";
import useOrderSearch from "@/common/hooks/useOrderSearch";

const DateRangeCalendar = ({
        onStartChange = () => {},
        onEndChange = () => {},
        onSubmit = () => {},
    }: DateRangeCalendarProps) => {

    const [showStartCalendar, setShowStartCalendar] = useState<boolean>(false)
    const [showEndCalendar, setShowEndCalendar] = useState<boolean>(false)

    const { persistedFilterQuery }: OrderSearchQuery = useOrderSearch()
    const [customPeriod, setCustomPeriod] = useState<DateRange>({
        ...persistedFilterQuery
    })

    const handleStartDateChange = (date: Date) => {
        setCustomPeriod(prev => ({
            ...prev,
            fromCreateDate: date
        }))
        onStartChange(validateDateFormat(date, Formats.DATE_DMY))
    }

    const handleEndDateChange = (date: Date) => {
        setCustomPeriod(prev => ({
            ...prev,
            toCreateDate: date
        }))
        onEndChange(validateDateFormat(date, Formats.DATE_DMY))
    }

    const handleSubmit = () => {
        onSubmit({
            fromCreateDate: validateDateFormat(customPeriod.fromCreateDate, Formats.DATE_DMY),
            toCreateDate: validateDateFormat(customPeriod.toCreateDate, Formats.DATE_DMY),
        })
    }

    return (
        <div className="custom-calendar">
            <div className="d-flex py-1" style={{ width: '360px' }}>
                <Form.Control
                    className="border-rounded-1 mx-1"
                    placeholder="Начало"
                    value={checkIfEmpty(customPeriod.fromCreateDate) && validateDateFormat(customPeriod.fromCreateDate, Formats.DATE_DMY)}
                    readOnly
                    onClick={() => {
                        setShowStartCalendar(true)
                        setShowEndCalendar(false)
                    }}
                />
                <Form.Control
                    className="border-rounded-1 mx-1"
                    placeholder="Конец"
                    value={checkIfEmpty(customPeriod.toCreateDate) && validateDateFormat(customPeriod.toCreateDate, Formats.DATE_DMY)}
                    readOnly
                    onClick={() => {
                        setShowStartCalendar(false)
                        setShowEndCalendar(true)
                    }}
                />
                <DefaultButton
                    className="border"
                    variant="outline-light"
                    onClick={handleSubmit}
                >
                    <Check color="green" />
                </DefaultButton>
            </div>
            <div>
                {showStartCalendar && (
                    <Calendar
                        key="calendar-start"
                        className="border-none custom-calendar"
                        value={customPeriod?.fromCreateDate}
                        onChange={handleStartDateChange}
                    />
                )}
                {showEndCalendar && (
                    <Calendar
                        key="calendar-end"
                        className="border-none custom-calendar"
                        value={customPeriod?.toCreateDate}
                        onChange={handleEndDateChange}
                    />
                )}
            </div>
        </div>
    )
}

interface DateRangeCalendarProps {
    onStartChange?: (dateStr: string) => void,
    onEndChange?: (dateStr: string) => void,
    onSubmit?: (range: DateRange) => void,
}

export default DateRangeCalendar
