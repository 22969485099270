import { CitySearch } from "../models/address"
import { CityApiPageResponse } from "../models/api/v0/address.dto"
import requests from "./apiAgent"

export const CityService = {
    getAll: async (query: CitySearch): Promise<CityApiPageResponse> => {
        return requests.get('/cities', query)
            .then((response) => {
                let currentPage = response.number + 1
                let currentElements: number

                if (currentPage > 1) {
                    currentElements = response.size * (currentPage - 1) + response.numberOfElements 
                } else {
                    currentElements = response.numberOfElements
                }

                return {
                    cities: response.content,
                    currentPage: currentPage,
                    isLast: response.last,
                    total: response.totalElements,
                    left: currentElements,
                    totalPages: response.totalPages,
                }
            })
    },
}