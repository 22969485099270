import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { Spinner } from "react-bootstrap"

import routeConfig from "./common/config/routeConfig"
import queryClientConfig from "./common/config/queryClientConfig"

const App = () => {
	const router = createBrowserRouter(routeConfig)
  	const queryClient = new QueryClient(queryClientConfig)

	return (
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} fallbackElement={<Spinner />} />
		</QueryClientProvider>
	)
}

export default App
