import React, {useEffect, useState} from "react";
import {MapContainer, TileLayer, Marker, useMap} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Address } from "@/common/models/address"

const customIcon = new L.Icon({
    iconUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

function LocationMarker({position}) {
    return position === null ?
        <></>
        : (
        <Marker position={position} icon={customIcon}>
        </Marker>
    );
}

const MapComponent = ({item}: MapComponentProps) => {
    const [position, setPosition] = useState({
        lat: item?.point?.lat,
        lng: item?.point?.lon
    })
    useEffect(() => {
        setPosition({
            lat: item?.point?.lat,
            lng: item?.point?.lon
        })

    }, [item?.point?.lat, item?.point?.lon])


    return (
        <MapContainer
            center={[position?.lat, position?.lng]} // Initial map center
            zoom={16}
            style={{height: "300px", width: "100%", position: "relative", zIndex: 0}}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <UpdateMapCenter position={position} />
            <LocationMarker position={position} />
        </MapContainer>
    )
}

const UpdateMapCenter = ({ position }) => {
    const map = useMap();

    useEffect(() => {
        map.setView([position.lat, position.lng], map.getZoom(), {
            animate: true
        });
    }, [position, map]);

    return <></>;
};

interface MapComponentProps {
    item: Address,
}



export default MapComponent;