import React, {useState} from 'react'
import {Badge, Table} from 'react-bootstrap'
import CouriersForm from "@/pages/courier/components/CouriersForm";

const CouriersContentTable = ({ data }: CouriersContentTableProps) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedCourier, setSelectedCourier] = useState(null)
    const [deleteMode, setDeleteMode] = useState<boolean>(false)

    const handleShow = (courier) => {
        setSelectedCourier(courier)
        setShowModal(true)
    }
    return (
        <div style={{ overflowY: 'auto' }}>
            <Table bordered striped size="sm">
                <thead>
                    <tr>
                        <th>Номер телефона</th>
                        <th>ФИО</th>
                        <th>Дата рождения</th>
                        <th>ИИН</th>
                        <th>Город</th>
                        <th>Наличие авто</th>
                        <th>Номер договора</th>
                        <th>Дата начала договора</th>
                        <th>Дата завершения договора</th>
                        <th>Адрес проживания курьера</th>
                        <th>Комментарии</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td className="d-flex flex-column justify-content-center align-items-center">
                                {item.phone}
                                <Badge
                                    key={"Детали курьера"}
                                    className="w-75 text-center border-rounded-1 bg-light text-dark cursor mb-1 py-1"
                                    onClick={() => handleShow(item)}
                                >Детали</Badge>
                                <Badge
                                    key={"Удалить курьера"}
                                    className="w-75 text-center bg-danger text-light cursor mb-1 py-1"
                                    onClick={() => {
                                        setSelectedCourier(item)
                                        setDeleteMode(true)
                                        setShowModal(true)
                                    }}>Удалить</Badge>
                            </td>
                            <td>{item.lastName + ' ' + item.firstName + ' ' + (item.middleName || '')}</td>
                            <td>{item.birthdate}</td>
                            <td>{item.iin}</td>
                            <td>{item.city.name}</td>
                            <td>{item.hasCar ? 'Есть' : 'Отсутствует'}</td>
                            <td>{item.contractNumber}</td>
                            <td>{item.contractStartDate}</td>
                            <td>{item.contractEndDate}</td>
                            <td>{item.courierAddress}</td>
                            <td>{item.comments}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {showModal && (
                <CouriersForm deleteMode={deleteMode} setDeleteMode={setDeleteMode} courierItem={selectedCourier} showModal={showModal} setShowModal={setShowModal} />
            )}
        </div>
    )
}

interface CouriersContentTableProps {
    data: any[],
}

export default CouriersContentTable