import { User } from '@/common/models/user'
import React from 'react'
import {Roles} from "@/common/constants/roles";

const UserProfile = ({ user, onSignOut }: UserProfileProps) => {

    if (!user) {
        return
    }

    return (
        <div className="d-flex bg-light p-2 border-rounded-2">
            <div>
                <span className='fw-medium'>{user.username}</span>
                <div>
                    {user.roles.map((role, index) => (
                        <span key={index} className="text-muted fw-medium">
                            {roleLabels[role]}
                        </span>
                    ))}
                </div>
                <div>
                    <span 
                        className="text-danger cursor fw-medium"
                        onClick={() => onSignOut()}>
                        Выйти
                    </span>
                </div>
            </div>
        </div>
    )
}

/**
 * TODO: Add i18n for managing text resources!!!
 */
const roleLabels = {
    [Roles.Manager]: 'Менеджер',
    [Roles.Client]: 'Клиент',
}

interface UserProfileProps {
    user: User,
    onSignOut: () => void,
}

export default UserProfile