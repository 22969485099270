import * as Yup from 'yup'

const addressSchema = Yup.object().shape({
    city: Yup.object().shape({
        id: Yup.number().required('Укажите город'),
    }),
    street: Yup.string().required('Укажите улицу'),
    apartment: Yup.string().notRequired(),
    building: Yup.string().required('Укажите дом'),
    comment: Yup.string().notRequired(),
});

const companySchema = Yup.object().shape({
    name: Yup.string().required('Укажите название компании'),
    bin: Yup.string()
        .length(12, 'Неправильный БИН')
        .notRequired(),
});

const deliveryPartySchema = Yup.object().shape({
    address: addressSchema,
    company: companySchema,
    contactName: Yup.string().notRequired(),
    phone: Yup.string()
        .required('Укажите телефон')
        .matches(/^(?:\+?\d{11})$/, 'Неправильный формат'),
});

const orderItemSchema = Yup.object().shape({
    deliveryReceiver: deliveryPartySchema,
    deliveryType: Yup.string().required('Укажите тип доставки'),
    parcelType: Yup.string().required('Укажите тип посылки'),
    comments: Yup.string().notRequired(),
});

export const validationSchema = Yup.object().shape({
    deliverySender: deliveryPartySchema,
    orderItems: Yup.array().of(orderItemSchema),
});