import React, {useCallback, useEffect, useState} from "react"
import { BoxSeamFill, ChevronDown, ChevronUp } from "react-bootstrap-icons"
import {useFormikContext} from "formik";

import { OrderCreateData } from "@/common/models/order"
import { OrderCreateOwnerRole } from "@/common/constants/order"
import Input from "@/components/controls/input/Input"
import Heading from "@/components/typography/Heading"
import CityAsyncSelect from "@/components/controls/select/CityAsyncSelect"
import { FormikSetter, SelectOption } from "@/common/models/util"
import AddressAsyncSelect from "@/pages/onboard/components/AddressAsyncSelect";
import {Row} from "react-bootstrap";
import {checkIfEmpty} from "@/common/utils/utils";
import CompanyAsyncSelect from "@/pages/order-create/components/forms/CompanyAsyncSelect";
import InputPhoneMasked from "@/components/controls/input/InputPhoneMasked"
import { SuggestCompanyPart } from "@/common/models/company"
import { AddressData } from "@/common/models/address"

const DeliveryPartyForm = ({ disabled = false, type, role, highlightFor, initialValues, setFieldValue }: DeliveryPartyFormProps) => {
    const [isAutofill, setIsAutofill] = useState(type.includes(highlightFor))
    const [show, setShow] = useState<boolean>(!isAutofill)
    const {values} = useFormikContext<OrderCreateData>()
    const [selectedCompany, setSelectedCompany] = useState(null)
    useEffect(() => {
        setIsAutofill(type.includes(highlightFor))
    }, [highlightFor, type, initialValues])

    const getCityDefaultValue = useCallback((): SelectOption | null => {
        if (!initialValues) {
            return
        }

        if (role === 'Sender') {
            return {
                value: initialValues.deliverySender.address.city?.id,
                label: initialValues.deliverySender.address.city?.name,
            }
        } else {
            if(selectedCompany !== null) {
                return {
                    value: selectedCompany?.city.id ?? initialValues.orderItems[0].deliveryReceiver.address.city?.id,
                    label: selectedCompany?.city.name ?? initialValues.orderItems[0].deliveryReceiver.address.city?.name,
                }
            } else {
                return {
                    value: initialValues.orderItems[0].deliveryReceiver.address.city?.id,
                    label: initialValues.orderItems[0].deliveryReceiver.address.city?.name,
                }
            }
        }
    }, [initialValues, role, selectedCompany])
    const getCompanyDefault = (): SuggestCompanyPart | null => {
        if(selectedCompany)
            return selectedCompany
        return {
            ...values.orderItems[0].deliveryReceiver.company,
            address: {...values.orderItems[0].deliveryReceiver.company.address}
        }
    }

    const getBuildingDefault = (): AddressData | null => {
        return {
            building: selectedCompany?.building ?? values.orderItems[0].deliveryReceiver?.address?.building,
            street: selectedCompany?.street ?? values.orderItems[0].deliveryReceiver?.address?.street,
            city: selectedCompany?.city ?? {
                id: selectedCompany?.city?.id ?? values.orderItems[0].deliveryReceiver?.address?.city?.id,
                name: selectedCompany?.city?.name ?? values.orderItems[0].deliveryReceiver?.address?.city?.name,
            },
            point: selectedCompany?.address?.point ?? values.orderItems[0].deliveryReceiver?.address?.point ?? null,
        }
    }

    const handleAddressChange = useCallback((address: AddressData, callback: FormikSetter) => {
        callback("orderItems[0].deliveryReceiver.address.city.id", checkIfEmpty(address?.cityId ?? getCityDefaultValue()?.value))
        callback("orderItems[0].deliveryReceiver.address.city.name", checkIfEmpty(address?.cityName ?? getCityDefaultValue()?.label))
        callback("orderItems[0].deliveryReceiver.address.building", checkIfEmpty(address?.building))
        callback("orderItems[0].deliveryReceiver.address.street", checkIfEmpty(address?.street))
        callback("orderItems[0].deliveryReceiver.address.point", address?.point)
    }, [getCityDefaultValue])

    const handleCompanyChange = (company: SuggestCompanyPart, callback: FormikSetter) => {
        callback("orderItems[0].deliveryReceiver.company.name", checkIfEmpty(company?.name))
        callback("orderItems[0].deliveryReceiver.address.city.id", Number(company?.city?.id))
        callback("orderItems[0].deliveryReceiver.address.city.name", checkIfEmpty(company?.city?.name))
        callback("orderItems[0].deliveryReceiver.address.street", checkIfEmpty(company?.address?.street))
        callback("orderItems[0].deliveryReceiver.address.building", checkIfEmpty(company?.address?.building))
        callback("orderItems[0].deliveryReceiver.address.apartment", checkIfEmpty(company?.address?.apartment))
        callback("orderItems[0].deliveryReceiver.address.comment", checkIfEmpty(company?.address?.addressComment ?? values?.orderItems[0]?.deliveryReceiver?.address?.comment))
        callback("orderItems[0].deliveryReceiver.contactName", checkIfEmpty(company?.contactName))
        callback("orderItems[0].deliveryReceiver.address.point", company?.address?.point)
        setSelectedCompany(company)
    }

    const handleCityChange = useCallback((option, setFieldValue) => {
        setFieldValue(`${type}.address.city.id`, Number(option.value))
        setFieldValue(`${type}.address.city.name`, option.label)
        setFieldValue(`${type}.address.street`, "")
        setFieldValue(`${type}.address.building`, "")
        setFieldValue(`${type}.address.apartment`, "")
        setFieldValue(`${type}.address.point`, null)
    }, [type])

    return (
        <div>
            <div className="py-3">
                <div
                    className={`d-flex py-2 px-3 border border-rounded-1 cursor ${isAutofill ? 'bg-primary text-white' : 'bg-light'}`}
                    onClick={() => setShow(!show)}
                >
                    <Heading
                        size="sm"
                        icon={<BoxSeamFill />}
                    >
                        {type === 'deliverySender' ? 'Отправитель' : 'Получатель'}
                        {isAutofill && (
                            <span className="mx-1 opacity-50">— Использованы ваши данные</span>
                        )}
                    </Heading>
                    <div className="d-flex align-items-center ms-auto">
                        {show ? (
                            <ChevronUp />
                        ) : (
                            <ChevronDown />
                        )}
                    </div>
                </div>
            </div>
            {show && (
                <div className="w-100 d-flex flex-column justify-content-center">
                    {
                        (role !== 'Sender') ? (
                            <Row
                                className="mb-3"
                            >
                                <span>Название компании<span className="text-danger">*</span></span>
                                <CompanyAsyncSelect
                                    onSelect={(company) => handleCompanyChange(company, setFieldValue)}
                                    onInputChange={value => setFieldValue("orderItems[0].deliveryReceiver.company.name", value)}
                                    prevCompany={getCompanyDefault()}
                                />
                            </Row>
                        ) : (
                            <Input
                                className="py-2"
                                id={`${type}.company.name`}
                                label={"Название компании"}
                                name={`${type}.company.name`}
                                placeholder={"Название компании"}
                                disabled={disabled}
                                required
                            />
                        )
                    }

                    <Input
                        className="py-2"
                        id={`${type}.company.bin`}
                        label={"БИН компании"}
                        name={`${type}.company.bin`}
                        disabled={disabled}
                    />
                    <div>
                        <div>
                            <span>Город<span className="text-danger">*</span></span>
                        </div>
                        <div>
                            <CityAsyncSelect
                                key={selectedCompany?.city?.id}
                                name={role}
                                defaultValue={getCityDefaultValue()}
                                onChange={(option) => handleCityChange(option, setFieldValue)}
                            />
                        </div>
                    </div>
                    <Input
                        className="py-2"
                        id={`${type}.address.street`}
                        label={"Улица"}
                        name={`${type}.address.street`}
                        placeholder={""}
                        disabled={disabled}
                        required
                    />
                    {
                        (role !== 'Sender' ? (
                            <Row className="mb-3">
                                <span>Дом<span className="text-danger">*</span></span>
                                <AddressAsyncSelect
                                    key={(values as any)?.orderItems[0]?.deliveryReceiver?.address?.street}
                                    prevAddress={getBuildingDefault()}
                                    onSelect={(address) => handleAddressChange(address,setFieldValue)}
                                    onInputChange={(value) => setFieldValue('orderItems[0].deliveryReceiver.address.building', value)}
                                />
                            </Row>
                        ) : (
                            <Input
                                className="py-2"
                                id={`${type}.address.building`}
                                label={"Дом"}
                                name={`${type}.address.building`}
                                placeholder={""}
                                disabled={disabled}
                                required
                            />
                        ))
                    }
                    <Input
                        className="py-2"
                        id={`${type}.address.apartment`}
                        label={"Офис/кв."}
                        name={`${type}.address.apartment`}
                        placeholder={""}
                        disabled={disabled}
                    />
                    <Input
                        className="py-2"
                        id={`${type}.address.comment`}
                        label={"Дополнительные комментарии по адресу"}
                        name={`${type}.address.comment`}
                        placeholder={""}
                        as={"textarea"}
                        style={{minHeight: "3.5rem"}}
                        disabled={disabled}
                    />
                    <InputPhoneMasked
                        className="py-2"
                        id={`${type}.phone`}
                        name={`${type}.phone`}
                        label="Номер телефона"
                        disabled={disabled}
                        required
                        onMaskChange={(value: string) => {
                            setFieldValue(`${type}.phone`, value)
                        }}
                    />
                    <Input
                        className="py-2"
                        id={`${type}.contactName`}
                        label={"Контактное лицо"}
                        name={`${type}.contactName`}
                        placeholder={""}
                        disabled={disabled}
                    />
                </div>
            )}
        </div>
    )
}

interface DeliveryPartyFormProps {
    role: OrderCreateOwnerRole,
    type: DeliveryPartyType,
    highlightFor: OrderCreateOwnerRole,
    disabled?: boolean,
    /**
     * Needed to set city, since it's not a formik component.
     */
    initialValues?: any,
    setFieldValue: (field: string, value: any) => Promise<any>,
}

type DeliveryPartyType = 'deliverySender' | 'orderItems[0].deliveryReceiver'

export default DeliveryPartyForm
