import { ReportCellLoad } from '@/common/models/report'
import React from 'react'
import { Table } from 'react-bootstrap'

const ReportCellLoadTable = ({ data }: ReportCellLoadTableProps) => {

    return (
        <div>
            <Table bordered striped size="sm">
                <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Кол-во в ячейках</th>
                        <th>От 1 до 4 дня</th>
                        <th>От 5 до 9 дней</th>
                        <th>От 10 до 19 дней</th>
                        <th>От 20 до 29 дней</th>
                        <th>Свыше 30 дней</th>
                    </tr>
                </thead>
                <tbody>
                    {data.rows.map((row, index) => (
                        <tr key={index}>
                            <td>{row.date}</td>
                            <td>{row.totalCount}</td>
                            <td>{row.count1To4Days}</td>
                            <td>{row.count5To9Days}</td>
                            <td>{row.count10To19Days}</td>
                            <td>{row.count20To29Days}</td>
                            <td>{row.count30AndOlder}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

interface ReportCellLoadTableProps {
    data: ReportCellLoad,
}

export default ReportCellLoadTable