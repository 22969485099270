import React, { ReactNode } from 'react'

const NavItem = ({ icon, disabled = false, onClick = () => {} }: NavItemProps) => {

    return (
        <div
            onClick={onClick} 
            className={`d-flex flex-column align-items-center bg-light border border-rounded-2 p-2 ${disabled && 'opacity-25'}`}>
            {icon}
        </div>
    )
}

interface NavItemProps {
    icon: ReactNode,
    disabled?: boolean,
    onClick?: () => void,
}

export default NavItem