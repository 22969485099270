import { OrderSearchQuery, OrderItem } from "@/common/models/order"
import { OrderApiPageResponse } from "@/common/models/api/v0/order.dto"
import requests from "./tgAgent"

/**
 * TODO: Temporary solution to avoid pagination. Should be removed on further releases.
 */
const MAX_PAGE_SIZE = 10_000

const OrderService = {
    getAll: async (query: OrderSearchQuery): Promise<OrderApiPageResponse> => {
        query.size = MAX_PAGE_SIZE
        return requests.get('/order-items', query)
            .then((response) => {
                let currentPage = response.number + 1
                let currentElements: number

                if (currentPage > 1) {
                    currentElements = response.size * (currentPage - 1) + response.numberOfElements 
                } else {
                    currentElements = response.numberOfElements
                }

                return {
                    orders: response.content,
                    currentPage: currentPage,
                    isLast: response.last,
                    total: response.totalElements,
                    left: currentElements,
                    totalPages: response.totalPages,
                }
            })
    },
    getById: async (id: number): Promise<OrderItem> => {
        return requests.get(`/order-items/${id}`)
    },
    changeStatus: async (orderItemId: number, event: string): Promise<string> => {
        return requests.post(`/order-items/${orderItemId}/change-status?event=${event}`)
    }
}

export default OrderService