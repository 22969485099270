import React, { useEffect, useRef, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import ReactToPrint from 'react-to-print'

import './index.css'
import { OrderItem } from '@/common/models/order'
import { buildDeliveryPartyString } from '@/common/utils/utils'
import Heading from '@/components/typography/Heading'

const OrderListPrintPage = () => {
    const componentRef = useRef()
    const location = useLocation()

    const [courierName, setCourierName] = useState<string>()

    const orders: OrderItem[] = location.state?.orders

    useEffect(() => {
        if (orders) {
            document.title = `Печать реестра ${orders.length}`
            document.getElementById('print-button').click()
            let order = orders.find(o => o.courier)
            setCourierName(order.courier.lastName + ' ' + order.courier.firstName) 
        }
    }, [orders])

    if (!orders || orders.length < 1) {
        return <div>No order details available.</div>
    }

    return (
        <div className="p-3" ref={componentRef}>
            <div>
                <Heading size="4">{new Date().toLocaleDateString()}</Heading>
                <div className="d-flex justify-content-between mt-5">
                    <span>Принято: {orders.length}, {courierName}</span>
                    <span>Подпись курьера: ____________</span>
                    <span>Маршрут: ____________</span>
                </div>
            </div>
            <Table className="mt-4 print-table" bordered striped size="sm">
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Получатель</th>
                        <th>ФИО</th>
                        <th>Должность</th>
                        <th>Подпись</th>
                        <th>Выдана</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((o, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{buildDeliveryPartyString(o.deliveryReceiver)}</td>
                            <td className="w-25"></td>
                            <td className="w-25"></td>
                            <td style={{ width: '5%' }}></td>
                            <td style={{ width: '10%' }}></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="d-none">
                <ReactToPrint
                    trigger={() => <button id="print-button">Print</button>}
                    content={() => componentRef.current}
                />
            </div>
        </div>
    )
}

export default OrderListPrintPage