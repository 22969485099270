import { DeliveryProcessType } from "@/common/constants/deliveryProcess"

/**
 * event.type = null — the event is common for both in city and inter city deliveries. 
 */
export const orderStatusMap: OrderStatusParam = {
    /**
     * Positive branch.
     */
    'READY_FOR_PICKUP': {
        events: [
            {
                name: 'Курьер назначен',
                event: 'ASSIGN_PICKUP_COURIER',
            },
        ]
    },
    'DELIVERED_TO_WAREHOUSE': {
        events: [
            {
                name: 'Принят складом по количеству',
                event: 'WAREHOUSE_ACCEPT',
            },
            {
                name: 'Доставка не дошла до получателя',
                event: 'WAREHOUSE_DELIVERY_REPEATED',
            }
        ],
    },

    'ACCEPTED_BY_WAREHOUSE': {
        events: [
            {
                name: 'Начать маркировку',
                event: 'WAREHOUSE_BEGIN_MARKING',
            },
        ],
    },
    'MARKING_AT_WAREHOUSE': {
        events: [
            {
                name: 'Завершить маркировку',
                event: 'WAREHOUSE_FINISH_MARKING',
            },
        ],
    },
    'MARKED_AT_WAREHOUSE': {
        events: [
            {
                name: 'Начать сортировку',
                event: 'WAREHOUSE_BEGIN_SORTING',
            },
        ],
    },
    'SORTING_AT_WAREHOUSE': {
        events: [
            {
                name: 'Завершить сортировку',
                event: 'WAREHOUSE_FINISH_SORTING',
            },
        ],
    },
    'SORTED_AT_WAREHOUSE': {
        events: [
            {
                name: 'Готово к доставке',
                event: 'WAREHOUSE_CONFIRM_DELIVERY',
            }
        ],
    },
    'READY_FOR_DEPARTURE_DELIVERY': {
        events: [
            {
                type: DeliveryProcessType.INTER_CITY,
                name: 'Назначен курьер на отправление',
                event: 'WAREHOUSE_ASSIGN_DELIVERY_COURIER',
            }
        ]
    },
    'DELIVERY_IN_TRANSIT': {
        events: [
            {   
                type: DeliveryProcessType.INTER_CITY,
                name: 'Готов к забору',
                event: 'MANAGER_CONFIRM_ARRIVAL',
            }
        ]
    },
    'READY_FOR_ARRIVAL_DELIVERY': {
        events: [
            {
                type: DeliveryProcessType.INTER_CITY,
                name: 'Назначен курьер на забор',
                event: 'ASSIGN_PICKUP_ARRIVAL_COURIER',
            }
        ]
    },
    'READY_FOR_DELIVERY': {
        events: [
            {
                name: 'Назначен курьер на доставку',
                event: 'WAREHOUSE_ASSIGN_DELIVERY_COURIER'
            }
        ]
    },
    'DELIVERED_BY_COURIER': {
        events: [
            {
                name: 'Подтвердить доставку',
                event: 'MANAGER_CONFIRM_DELIVERY'
            },
        ]
    },

    /**
     * Negative branch.
     */
    'PENDING_CLIENT_CLARIFICATION': {
        events: [
            {
                type: DeliveryProcessType.IN_CITY,
                name: 'Курьер не успел',
                event: 'WAREHOUSE_DELIVERY_RETRY_ON_COURIER_FAULT',
            },
            {
                type: DeliveryProcessType.IN_CITY,
                name: 'Клиента не было на месте',
                event: 'WAREHOUSE_DELIVERY_RETRY_ON_CLIENT_UNAVAILABLE',
            },
            {
                type: DeliveryProcessType.IN_CITY,
                name: 'Неправильный адрес',
                event: 'WAREHOUSE_DELIVERY_RETRY_ON_INVALID_ADDRESS',
            },
            {
                type: DeliveryProcessType.IN_CITY,
                name: 'Отправить на уничтожение',
                event: 'WAREHOUSE_DELIVERY_ABORTED_DESTROY',
            },
            {
                type: DeliveryProcessType.IN_CITY,
                name: 'Вернуть отправителю',
                event: 'WAREHOUSE_DELIVERY_ABORTED_RETURN',
            },
            {
                type: DeliveryProcessType.INTER_CITY,
                name: 'Возврат отправителю (Завершено)',
                event: 'DELIVERY_ABORTED_RETURN_TO_SENDER',
            },
        ]
    }
}

export interface OrderStatusParam {
    [status: string]: OrderAction
}

export interface OrderAction {
    events: OrderEventItem[],
}

export interface OrderEventItem {
    type?: DeliveryProcessType,
    name: string,
    event: string,
}
