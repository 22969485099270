import * as yup from 'yup';
import {RegexPatterns} from "@/common/constants/regexPatterns";
import {checkIfEmpty} from "@/common/utils/utils";

export const getInitialValues = (company, phone) => {
    return {
        name: checkIfEmpty(company?.name),
        bin: checkIfEmpty(company?.bin),
        contactPhone: checkIfEmpty(phone),
        city: checkIfEmpty(company?.city?.name),
        street: checkIfEmpty(company?.streetName),
        building: checkIfEmpty(company?.houseNumber),
        apartment: checkIfEmpty(company?.apartment),
        addressComments: checkIfEmpty(company?.addressComments)
    }

}

const name = yup.string()
    .required("Наименование организации обязательна")
    .matches(RegexPatterns.companyNames, "Неверный формат имени")
const contactPhone = yup.string()
    .required("Укажите контактный номер телефона")
    .matches(RegexPatterns.phoneSize, "Укажите контактный номер телефона")
const bin = yup.string()
    .required("Укажите БИН организации")
    .matches(RegexPatterns.bin, "БИН состоит из 12 цифр")
const city = yup.string().nonNullable()
    .required("Город обязателен")
const street = yup.string()
    .required("Укажите улицу (фактический адрес) организации")
    .max(128, "Слишком длинный адрес")
const building = yup.string()
    .required("Укажите дом (фактический адрес) организации")
    .max(128, "Слишком длинный адрес")
const apartment = yup.string()
    .max(128, "Слишком длинный адрес")
const addressComments = yup.string()
    .max(128, 'Слишком длинный комментарий')


export const CompanyModalSchema = {
    custom: yup.object().shape({
        name,
        bin,
        contactPhone,
        city,
        street,
        building,
        apartment,
        addressComments
    })
}
