import OrderCreateFormProvider from '@/common/providers/OrderCreateFormProvider'
import React from 'react'
import OrderCreateFormBody from './components/OrderCreateFormBody'
import Heading from '@/components/typography/Heading'
import { Col, Container, Row } from 'react-bootstrap'

const OrderCreatePage = () => {
    return (
        <div className="px-3 pt-3">
            <div>
                <Heading size="2"withPadding>
                    Создание заказа
                </Heading>
            </div>

            <div>
                <OrderCreateFormProvider>
                    <Container className="p-0" fluid>
                        <Row>
                            <Col>
                                <div className="px-4 py-3 border border-rounded-2" style={{ minHeight: '250px' }}>
                                    <OrderCreateFormBody />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </OrderCreateFormProvider>
            </div>
        </div>
    )
}

export default OrderCreatePage