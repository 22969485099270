import {Courier, CourierSearchQuery} from "../models/courier"
import { CourierApiPageResponse, CourierApiResponse } from "../models/api/v0/courier.dto"
import requests from "./apiAgent"

const CourierService = {
    getAll: async (query?: CourierSearchQuery): Promise<CourierApiPageResponse> => {
        return requests.get('/couriers', query)
            .then((response) => {
                let currentPage = response.number + 1
                let currentElements: number

                if (currentPage > 1) {
                    currentElements = response.size * (currentPage - 1) + response.numberOfElements 
                } else {
                    currentElements = response.numberOfElements
                }

                return {
                    couriers: response.content,
                    currentPage: currentPage,
                    isLast: response.last,
                    total: response.totalElements,
                    left: currentElements,
                    totalPages: response.totalPages,
                }
            })
    },

    createCourier: async (body: Courier): Promise<CourierApiResponse> => {
        return requests.post('/couriers', body)
    },

    updateCourier: async (id: number, body: Courier): Promise<CourierApiResponse> => {
        return requests.put(`/couriers/${id}`, body)
    },

    deleteCourier: async (id: number) : Promise<void> => {
        return requests.delete(`/couriers/${id}`)
    },

    getById: async (id: number): Promise<CourierApiResponse> => {
        return requests.get(`/couriers/${id}`)
    }
}

export default CourierService
