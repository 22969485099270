import React from 'react'
import CouriersSection from './components/CouriersSection'

const CourierViewPage = () => {
    return (
        <div className="pt-3">
            <div>
                <p className="heading-2">Курьеры</p>
            </div>

            <div className="h-75">
                <CouriersSection />
            </div>
        </div>
    )
}

export default CourierViewPage
