import { makeAutoObservable } from "mobx"
import { Persistable } from "./persistable"

class CourierStore implements Persistable {

    order: any
    isPreparing: boolean = true
    telegramData: any

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.loadFromLocalStorage()
    }

    setOrder(order: any) {
        this.order = order
        localStorage.setItem('order', JSON.stringify(this.order))
    }

    setIsPreparing(isPreparing: boolean) {
        this.isPreparing = isPreparing
    }

    setTelegramData(data: any) {
        this.telegramData = data
    }

    loadFromLocalStorage(): void {
        const order = localStorage.getItem('order')
        const telegramData = localStorage.getItem('user-data')

        if (order) {
            this.order = JSON.parse(order)
        }

        if (telegramData) {
            this.telegramData = JSON.parse(telegramData)
        }
    }
}

const courierStore = new CourierStore()

export default courierStore
