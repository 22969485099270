import React, { useState } from 'react'
import { Navigate } from "react-router-dom";
import { observer } from 'mobx-react-lite'
import {Container, Form, Button, Nav, Row, Alert, Spinner} from 'react-bootstrap';

import useAuth from '@/common/hooks/useAuth'

const LoginPage = observer(() => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [error, setError] = useState('');
	const [validated, setValidated] = useState(false);
	const [username, setUsername] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const auth = useAuth();

	if (auth.isAuthenticated()) return <Navigate to="/" />;

	const handleLogin = async (e: React.FormEvent) => {
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
		}

		setValidated(true);
		if (username !== "" && password !== "") {
			try {
				setIsLoading(true);
				await auth.signIn(username, password);
				return;
			} catch (error) {
				console.log(error);
				handleLoginError(error);
			} finally {
				setIsLoading(false);
			}
		}
	}

	const handleInput = (e) => {
		setError('');
		if (e.target.id === 'usernameInput') {
			setUsername(e.target.value);
		} else {
			setPassword(e.target.value);
		}
	}

	const handleLoginError = (error) => {

		console.log(error);
		if(error.response.status === 401
			//   			TODO: add error code for unauthorized access in backend
			//   				&& error.response.data.errorCode === 'BAD_CREDENTIALS'
			&& error.response.body.errorMessage === "Bad credentials"
		) {
			setError('Неверный логин или пароль');
		} else {
			console.error("Can't handle it right now...");
		}
	}
	if (isLoading)
		return <Container className="vh-100 d-flex justify-content-center align-items-center">
			<Spinner animation={"border"}/>
		</Container>

	return (
		<Container className="vh-100 d-flex flex-column ">
			<Row className="vh-100  d-flex align-items-center justify-content-center">
				<div style={{ display: 'block',
					width: 600,
					padding: 30 }}>
					<Form noValidate validated={validated}>
						<Form.Group className='mt-3'>
							<Form.Label>Имя пользователя:</Form.Label>
							<Form.Control id="usernameInput"
										  type="text"
										  value={username}
										  required
										  placeholder="Введите email или имя пользователя"
										  onChange={handleInput}
							/>
						</Form.Group>
						<Form.Group className='mt-3'>
							<Form.Label>Пароль:</Form.Label>
							<Form.Control type="password"
										  value={password}
										  required
										  placeholder="Введите пароль"
										  onChange={handleInput}
							/>

						</Form.Group>
						{error && <Alert variant="danger">{error}</Alert>}
						<Container>
							<Row>
								<Button className='mt-3'
										variant="primary"
										type="submit"
										disabled={!(username.length > 0 && password.length > 0)}
										onClick={handleLogin}>
									Войти
								</Button>
								<Nav >
									<Nav.Item>
										<Nav.Link href="/signup">Регистрация</Nav.Link>
									</Nav.Item>
								</Nav>
							</Row>
						</Container>
					</Form>
				</div>
			</Row>
		</Container>
	)
});

export default LoginPage;