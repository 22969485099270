import { OrderCreateData } from "@/common/models/order"

export const initialValues: OrderCreateData = {
    deliverySender: {
        isCompany: true,
        address: {
            city: {
            },
            street: '',
            apartment: '',
            building: '',
            comment: '',
        },
        company: {
            name: '',
            bin: '',
        },
        contactName: '',
        phone: ''
    },
    orderItems: [
        {
            deliveryType: 'STANDARD',
            parcelType: 'ENVELOPE',
            deliveryReceiver: {
                isCompany: true,
                address: {
                    city: {
                    },
                    street: '',
                    apartment: '',
                    building: '',
                    point: null,
                    comment: '',
                },
                company: {
                    name: '',
                    bin: '',
                },
                contactName: '',
                phone: ''
            }
        }
    ]
}