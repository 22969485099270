import { useMemo, useState } from "react"

const usePage = () => {
    const [page, setPage] = useState<number>(1)

    const nextPage = () => {
        setPage(page + 1)
    }

    const previousPage = () => {
        setPage(page - 1)
    }

    const resetPage = () => {
        setPage(1)
    }

    const getCurrentPage = (): number => page !== 0 ? page - 1 : page

    const currentPage = useMemo(getCurrentPage, [page])

    return { page, nextPage, previousPage, currentPage, resetPage }
}

export default usePage
