import OrderSection from "./components/OrderSection"
import UserSection from "./components/UserSection"
import useCourier from "@/common/hooks/useCourier"

const TelegramOrderViewPage = () => {
    const { telegramData } = useCourier()

    return (
        <div>
            <UserSection user={telegramData.user} />
            <OrderSection />
        </div>
    )
}

export default TelegramOrderViewPage
