import * as yup from 'yup'

export const initialValues = {
    userName: "",
    password: "",
    confirmPassword: "",
    passwordCheckbox: false
}

const userName = yup
    .string()
    .required('Введите логин или почту')
    .test(
        'is-valid',
        'Некорректный логин или почта',
        function (value) {
            if (!value) return false;
            if (value.includes('@')) {
                return yup.string().email().isValidSync(value);
            }
            return yup.string()
                .matches(/^[a-zA-Z0-9_.-]*$/,
                    'Логин может содержать только буквы, цифры, точки, дефисы и подчеркивания')
                .isValidSync(value);
        }
    );

const password = yup
    .string()
    .required('Введите пароль')
    .min(8, 'Пароль должен содержать минимум 8 символов')
    .matches(/[A-Z]/, 'Пароль должен содержать хотя бы одну заглавную букву')
    .matches(/[a-z]/, 'Пароль должен содержать хотя бы одну строчную букву')
    .matches(/[0-9]/, 'Пароль должен содержать хотя бы одну цифру')
    .matches(/[@$!%*?&]/, 'Пароль должен содержать хотя бы один специальный символ (@$!%*?&)');

const confirmPassword = yup.string()
    .required('Подтвердите пароль')
    .oneOf([yup.ref('password'), null], 'Пароли должны совподать')

export const AuthValidationSchema = {
    custom: yup.object().shape({
        userName,
        password,
        confirmPassword
    })
}
