import { useState } from "react"

import useOrderCreateForm from "@/common/hooks/useOrderCreateForm"
import Heading from "@/components/typography/Heading"
import { StepActionBottomBar } from "."
import DefaultButton from "@/components/buttons/DefaultButton"
import useAuth from "@/common/hooks/useAuth"

const GetOrderOwnerRoleStep = () => {
    const form = useOrderCreateForm()
    const { user } = useAuth()

    const [senderChecked, setSenderChecked] = useState<boolean>(false)
    const [receiverChecked, setReceiverChecked] = useState<boolean>(false)

    return (
        <div>
            <div>
                <div className="d-flex mb-3">
                    <Heading size="sm">
                        Выберите один из вариантов
                    </Heading>
                    <StepActionBottomBar>
                        <DefaultButton variant="light" bordered onClick={form.goForward}>
                            Перейти дальше
                        </DefaultButton>
                    </StepActionBottomBar>
                </div>
                <RoleChoiceCard
                    title="Я — отправитель"
                    description="Использовать данные моей компании как Отправитель"
                    selected={senderChecked}
                    onSelect={() => {
                        form.setRole('Sender')
                        form.setRoleData(user.company, user.deliveryParty)
                        setSenderChecked(!senderChecked)
                        setReceiverChecked(false)
                    }}
                />
                <div style={{ height: "10px" }}></div>
                <RoleChoiceCard
                    title="Я — получатель"
                    description="Использовать данные моей компании как Получатель"
                    selected={receiverChecked}
                    onSelect={() => {
                        form.setRole('Receiver')
                        form.setRoleData(user.company, user.deliveryParty)
                        setSenderChecked(false)
                        setReceiverChecked(!receiverChecked)
                    }}
                />
            </div>
        </div>
    )
}

const RoleChoiceCard = ({ title, selected, disabled = false, description, onSelect }: RoleChoiceCardProps) => {
    const disabledState = !disabled ? 'cursor' : 'opacity-50'
    const selectedState = selected ? 'bg-primary text-white' : 'bg-light'

    return (
        <div
            className={`d-flex flex-column px-3 py-2 border border-rounded-1 ${disabledState} ${selectedState}`}
            onClick={() => !disabled && onSelect()}
        >
            <div>
                <Heading size="sm">{title}</Heading>
            </div>
            <div className="mt-2">
                <span>{description}</span>
            </div>
        </div>
    )
}

interface RoleChoiceCardProps {
    title: string,
    description: string,
    selected: boolean,
    disabled?: boolean,
    onSelect: () => void,
}

export default GetOrderOwnerRoleStep
