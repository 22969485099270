import React, { ReactNode } from 'react'
import Spacer from '../Spacer'

const Heading = ({ className, size, icon, color, withPadding = false, children }: HeadingProps) => {
    return (
        <div className={`d-flex align-items-center ${className} ${color && `text-${color}`} ${withPadding && 'pb-3'}`}>
            {icon}
            {icon && <Spacer width="5px" />}
            <span className={`heading-${size}`}>{children}</span>
        </div>
    )
}

/**
 * See ~/styles/typography for references.
 */
interface HeadingProps {
    size: '1' | '2' | '3' | '4' | 'sm',
    icon?: ReactNode,
    color?: string,
    className?: string,
    withPadding?: boolean,
    children: ReactNode
}

export default Heading