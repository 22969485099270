import { ReactNode } from "react"
import { Button, Spinner } from "react-bootstrap"

const DefaultButton = ({
    className,
    fillSpace = false,
    variant = 'primary',
    isLoading = false,
    disabled = false,
    bordered = false,
    submit = false,
    onClick = () => {},
    children,
   ...rest}: DefaultButtonProps) => {

    return (
        <Button
            {...rest}
            type={submit ? 'submit' : 'button'}
            className={`d-flex justify-content-center align-items-center border-rounded-2 ${bordered && 'border'} ${fillSpace && 'w-100'} ${className} btn-text`}
            variant={variant}
            onClick={onClick}
            disabled={isLoading || disabled}

        >
            {isLoading && <Spinner className="mx-1" size="sm" />}
            <div className="d-flex align-items-center mx-1 py-1">
                {isLoading ? 'Загрузка' : children}
            </div>
        </Button>
    )
}

interface DefaultButtonProps {
    className?: string,
    fillSpace?: boolean,
    variant?: string,
    isLoading?: boolean,
    disabled?: boolean,
    bordered?: boolean,
    submit?: boolean,
    onClick?: () => void,
    children: ReactNode,
    [options: string]: any
}

export default DefaultButton
