export class Statuses {
	static readonly courierFieldAccessibleStatuses = ['READY_FOR_PICKUP', 'READY_FOR_DELIVERY']
	static readonly printAccessibleStatuses = [
		'MARKED_AT_WAREHOUSE', 'SORTING_AT_WAREHOUSE', 'SORTED_AT_WAREHOUSE',
		'READY_FOR_DELIVERY', 'DELIVERY_COURIER_ASSIGNED', 'DELIVERY_IN_PROGRESS',
		'DELIVERED_BY_COURIER', 'DELIVERED_ACCEPTED_MANAGER',
	]
	static readonly courierAssignableStatuses = {
		'READY_FOR_PICKUP': 'PICKUP_COURIER_ASSIGNED',
		'READY_FOR_DELIVERY': 'DELIVERY_COURIER_ASSIGNED',
	}
}