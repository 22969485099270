const Spacer = ({ width: size, height }: SpacerProps) => {
    return <div
        style={{ width: size || "0", height: height || "0", display: 'inline-block' }}
    />
}

interface SpacerProps {
    width?: string,
    height?: string,
}

export default Spacer
