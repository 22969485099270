import React from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { Roles } from '../constants/roles'
import useAuth from '../hooks/useAuth'

const AuthorizedRoute = observer(({ allowedFor }: AuthorizedRouteProps) => {
    const { hasRole } = useAuth()

    if (!hasRole(allowedFor)) {
        return (
            <div>
                <span>Forbidden!!!</span>
            </div>
        )
    }

    return (
        <Outlet />
    )
})

interface AuthorizedRouteProps {
    allowedFor: Roles,
}

export default AuthorizedRoute