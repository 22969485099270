import { SuggestAddressesApiResponse } from "../models/api/v0/address.dto"
import { SuggestCompanyApiResponse } from "../models/api/v0/company.dto"
import { DeliveryPartyCompanyApiResponse } from "../models/api/v0/deliveryParty.dto"
import { CompanyApiRequest } from "../models/api/v0/company.dto"
import requests from "@/common/api/apiAgent";

export const CompanyService = {
    createClientCompany: async (body: CompanyApiRequest): Promise<DeliveryPartyCompanyApiResponse> => {
        return requests.post('/companies', body)
    },

    updateCompany: async (id: number, body: CompanyApiRequest): Promise<DeliveryPartyCompanyApiResponse> => {
        return requests.put(`/companies/${id}`, body)
    },

    suggestAddresses: async (body): Promise<SuggestAddressesApiResponse> => {
        return requests.post('/addresses/suggest', body)
    },
    suggestCompanies: async (name, size = 10): Promise<SuggestCompanyApiResponse> => {
        return requests.get(`/companies/suggest-by-name?name=${name}&size=${size}`)
    }
}