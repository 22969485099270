import React, {useState} from 'react'
import { Form, InputGroup } from 'react-bootstrap'

import { searchOptions } from './options'
import {Roles} from "@/common/constants/roles";

const SearchBar = ({
        onSelect,
        onChange,
        onEnter = () => {},
        className,
        role = Roles.Client,
    }: SearchBarProps) => {
    const [searchType, setSearchType] = useState('orderItemId')
    const [searchQuery, setSearchQuery] = useState('')

    const handleOptionChange = (e) => {
        setSearchType(e.target.value)
        onSelect(e.target.value)
        setSearchQuery("")
        onChange("")
    }

    const getInputType = (searchType) => {
        if(searchType === "orderItemId"
            || searchType === "orderNumber"
            || searchType === "receiver"
            || searchType === "bin"
            || searchType === "bin") {
            return "number"
        } else {
            return "text"
        }
    }

    const handleInputChange = (e) => {
        onChange(e.target.value)
        setSearchQuery(e.target.value)
    }

    return (
        <div className={`${className}`}>
            <div>
                <InputGroup className="d-flex">
                    <Form.Select
                        className="border-rounded-1 flex-shrink-1"
                        onChange={handleOptionChange}
                    >
                        {searchOptions[role].map((o: any, index) => (
                            <option key={index} value={o.value}>{o.text}</option>
                        ))}
                    </Form.Select>
                    <Form.Control
                        className="border-rounded-1 w-75"
                        onChange={handleInputChange}
                        value={searchQuery}
                        type={getInputType(searchType)}
                        onKeyUp={(e) => e.key === 'Enter' && onEnter()}
                    />
                </InputGroup>
            </div>
        </div>
    )
}

interface SearchBarProps {
    onSelect: (option: string) => void,
    onChange: (value: string) => void,
    onEnter?: () => void,
    className?: string,
    role?: string,
}

export default SearchBar
