import React, {useState} from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import { subDays, formatDate, subMonths, subYears } from "date-fns"
import {observer} from "mobx-react-lite";

import { createDateOptions, deliveryStatusOptions, deliveryTypeOptions, parcelTypeOptions } from "./options"
import DateRangeCalendar from "@/components/controls/calendar/DateRangeCalendar"
import { OrderSearchQuery } from "@/common/models/order"
import {validateDateFormat, formatPeriod} from "@/common/utils/utils";
import useOrderSearch from "@/common/hooks/useOrderSearch";
import DefaultButton from "@/components/buttons/DefaultButton";

const FilterBar = observer(({
        onChange = () => {},
    }: FilterBarProps) => {
    const [showCustomPeriod, setShowCustomPeriod] = useState<boolean>(false)
    const { persistedFilterQuery, persistFilterQuery }: OrderSearchQuery = useOrderSearch()

    const handlePeriodChange = (period: string) => {
        if(period === "*")
            handleClearPeriod()
        if (period === 'custom') {
            return setShowCustomPeriod(true)
        } else {
            setShowCustomPeriod(false)
        }

        const today: Date = new Date()
        const todayStr: string = formatDate(new Date(), 'dd-MM-yyyy')

        switch (period) {
            case '*':
                onChange({
                    ...persistedFilterQuery,
                    fromCreateDate: undefined,
                    toCreateDate: undefined,
                    createDateOption: '*'
                })
                break
            case 'today':
                onChange({
                    ...persistedFilterQuery,
                    fromCreateDate: todayStr,
                    toCreateDate: todayStr,
                    createDateOption: 'today'
                })
                break
            case 'weekly':
                const weekAgo: string = formatDate(subDays(today, 7), 'dd-MM-yyyy')
                onChange({
                    ...persistedFilterQuery,
                    fromCreateDate: weekAgo,
                    toCreateDate: todayStr,
                    createDateOption: 'weekly'
                })
                break
            case 'monthly':
                const monthAgo: string = formatDate(subMonths(today, 1), 'dd-MM-yyyy')
                onChange({
                    ...persistedFilterQuery,
                    fromCreateDate: monthAgo,
                    toCreateDate: todayStr,
                    createDateOption: 'monthly'
                })
                break
            case 'yearly':
                const yearAgo: string = formatDate(subYears(today, 1), 'dd-MM-yyyy')
                onChange({
                    ...persistedFilterQuery,
                    fromCreateDate: yearAgo,
                    toCreateDate: todayStr,
                    createDateOption: 'yearly'
                })
                break
        }
    }

    const handleStatusChange = (status: string) => {
        switch (status) {
            case '*':
                onChange({
                    ...persistedFilterQuery,
                    deliveryStatuses: deliveryStatusOptions.find(option => option.value === "*").query
                })
                break
            case 'NEW':
                onChange({
                    ...persistedFilterQuery,
                    deliveryStatuses: 'NEW'
                })
                break
            case 'IN_PROGRESS':
                onChange({
                    ...persistedFilterQuery,
                    deliveryStatuses: 'READY_FOR_PICKUP,DELIVERY_IN_PROGRESS'
                })
                break
            case 'DELIVERED':
                onChange({
                    ...persistedFilterQuery,
                    deliveryStatuses: 'DELIVERED_ACCEPTED_MANAGER'
                })
                break
            case 'NON_DELIVERED':
                onChange({
                    ...persistedFilterQuery,
                    deliveryStatuses: 'PENDING_CLIENT_CLARIFICATION,DELIVERY_DESTROYED,DELIVERY_RETURNED_TO_SENDER'
                })
                break
        }
    }

    const handleCustomPeriodSubmit = (range) => {
        setShowCustomPeriod(false)
        onChange({
            ...persistedFilterQuery,
            fromCreateDate: range?.fromCreateDate,
            toCreateDate: range?.toCreateDate,
            createDateOption: 'custom'
        })
    }

    const handleClearPeriod = () => {
        onChange({
            ...persistedFilterQuery,
            fromCreateDate: undefined,
            toCreateDate: undefined,
            createDateOption: '*'
        })
    }

    const handleClearFilterQuery = () => {
        onChange({
            deliveryStatuses: deliveryStatusOptions.find(option => option.value === "*").query
        })
    }

    const handleCreateDateChange = (e, reset = false) => {
        if(reset)
            return "*"
        return handlePeriodChange(e.target.value)
    }

    return (
        <Container className="p-0" fluid>
            <Row className="gx-2">
                <Col className="position-relative">
                    <Form.Select
                        className="border-rounded-1"
                        onChange={handleCreateDateChange}
                        value={persistedFilterQuery?.createDateOption ? persistedFilterQuery?.createDateOption : "*"}
                    >
                        {
                            createDateOptions.map((o, index) => {

                                if(o.value === "custom"
                                    && persistedFilterQuery?.fromCreateDate
                                    && persistedFilterQuery?.toCreateDate
                                ) {
                                    return <option key={index}
                                                   value={o.value}
                                                   className="d-none"
                                    >
                                        {formatPeriod(
                                            persistedFilterQuery?.fromCreateDate,
                                            persistedFilterQuery?.toCreateDate
                                        )}
                                    </option>
                                }
                                return <option
                                    key={index}
                                    value={o.value}
                                >{o.text}</option>
                            })
                        }
                        {
                            (
                                persistedFilterQuery?.createDateOption && persistedFilterQuery?.createDateOption !== "*" && (
                                    <option key={"clearPeriod"} value="*" className="bg-danger">Очистить</option>
                                )
                            )
                        }
                    </Form.Select>
                    {showCustomPeriod && (
                        <div style={{zIndex: 10}} className="bg-white border border-rounded-2 shadow position-absolute mt-2 p-2">
                            <DateRangeCalendar
                                onStartChange={(startDate) => {
                                    persistFilterQuery({
                                        ...persistedFilterQuery,
                                        fromCreateDate: validateDateFormat(startDate, "dd-MM-yyyy"),
                                        createDateOption: "custom"
                                    })
                                }}
                                onEndChange={(endDate) => {
                                    persistFilterQuery({
                                        ...persistedFilterQuery,
                                        toCreateDate: validateDateFormat(endDate, "dd-MM-yyyy"),
                                        createDateOption: "custom"
                                    })
                                }}
                                onSubmit={(range) => {
                                    handleCustomPeriodSubmit(range)
                                }}
                            />
                        </div>
                    )}
                </Col>
                <Col>
                    <Form.Select
                        className="border-rounded-1"
                        onChange={(e) => onChange({ ...persistedFilterQuery, deliveryType: e.target.value === '*' ? undefined : e.target.value})}
                        value={persistedFilterQuery?.deliveryType ? persistedFilterQuery?.deliveryType : "*"}
                    >
                        {deliveryTypeOptions.map((o, index) => (
                            <option key={index} value={o.value}>{o.text}</option>
                        ))}
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select
                        className="border-rounded-1"
                        onChange={(e) => onChange({ ...persistedFilterQuery,  parcelType: e.target.value === '*' ? undefined : e.target.value })}
                        value={persistedFilterQuery?.parcelType ? persistedFilterQuery?.parcelType : "*"}
                    >
                        {parcelTypeOptions.map((o, index) => (
                            <option key={index} value={o.value}>{o.text}</option>
                        ))}
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select
                        className="border-rounded-1"
                        onChange={(e) => handleStatusChange(e.target.value)}
                        value={deliveryStatusOptions
                            .filter(s =>
                                s.query === persistedFilterQuery?.deliveryStatuses)[0]?.value}
                    >
                        {deliveryStatusOptions.map((o, index) => (
                            <option key={index} value={o.value}>{o.text}</option>
                        ))}
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <div className="mt-3">
                    {
                        Object.values(persistedFilterQuery)?.filter(value => value !== undefined && value !== "*").length > 0 &&
                        <DefaultButton onClick={handleClearFilterQuery}>Сбросить фильтры</DefaultButton>
                    }
                </div>
            </Row>

        </Container>
    )
})

interface FilterBarProps {
    onChange: (option: OrderSearchQuery) => void,
}

export default FilterBar
