import { OrderSearchQuery, OrderCreateData, OrderItem } from "../models/order"
import { OrderCreateApiResponse, OrderItemApiResponse } from "../models/api/v0/order.dto"
import { OrderItemChangeApiResponse } from "../models/api/v0/order.dto"
import { OrderApiPageResponse } from "../models/api/v0/order.dto"
import requests from "./apiAgent"

/**
 * TODO: Temporary solution to avoid pagination. Should be removed on further releases.
 */
const MAX_PAGE_SIZE = 10_000

/**
 * TODO: Same as above.
 */
const shouldNotPaginate = (query: OrderSearchQuery): boolean | any => {
    return query.courier || query.zone 
        || query.receiverBin
}

const OrderService = {
    getAll: async (query: OrderSearchQuery): Promise<OrderApiPageResponse> => {
        if (shouldNotPaginate(query)) {
            query.size = MAX_PAGE_SIZE
        }

        return requests.get('/order-items', {...query, fetchMeasoft: false})
            .then((response) => {
                let currentPage = response.number + 1
                let currentElements: number

                if (currentPage > 1) {
                    currentElements = response.size * (currentPage - 1) + response.numberOfElements 
                } else {
                    currentElements = response.numberOfElements
                }

                return {
                    orders: response.content,
                    currentPage: currentPage,
                    isLast: response.last,
                    total: response.totalElements,
                    left: currentElements,
                    totalPages: response.totalPages,
                }
            })
    },

    getOrderItemChangeHistory: async (orderItemId: number): Promise<OrderItemChangeApiResponse> => {
    	if (orderItemId === null) {
			return { orderItemChanges: [] }
    	}

        return requests.get(`/order-items/${orderItemId}/order-item-change-history`)
        	.then((response) => {
        		return {
        			orderItemChanges: response.orderItemChangeHistories,
        		}
        	})
    },

    updateOrderItem: async (body: OrderItem, id: number): Promise<OrderItemApiResponse> => {
        return requests.put(`/order-items/${id}`, body)
    },

    create: async (body: OrderCreateData): Promise<OrderCreateApiResponse> => {
        return requests.post('/orders', body)
    },

    changeStatus: async (orderItemId: number, event: string): Promise<string> => {
        return requests.post(`/order-items/${orderItemId}/change-status?event=${event}`)
    }
}

export default OrderService
