import React, { useState } from 'react'
import { Form, InputGroup, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { BoxSeam } from 'react-bootstrap-icons'

import OrderListItem from './OrderCard'
import useSort from '@/common/hooks/useSort'
import { OrderItem } from '@/common/models/order'
import useSearch from '@/common/hooks/useSearch'

const OrderList = ({ data, isLoading = false }: OrderListProps) => {
    const { sortedData } = useSort<OrderItem>(data.orders, [{ key: 'createDate', direction: 'asc' }], () => {})

    const [searchType, setSearchType] = useState<string>('id')
    const [searchQuery, setSearchQuery] = useState<string>('')

    const { result } = useSearch<OrderItem>(sortedData, searchType, searchQuery)

    const handleSearchTypeChange = (value: string) => {
        setSearchType(value)
        setSearchQuery('')
    }

    const handleSearchInput = (value: string) => {
        setSearchQuery(value)
    }

    const navigate = useNavigate()

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="d-flex flex-column align-items-center">
                    <Spinner />
                    <span className="btn-text my-3">Загрузка заказов</span>
                </div>
            </div>
        )
    }

    if (!data || (data && data.orders.length < 1)) {
        return (
            <div className="border-dotted border-rounded-2 d-flex justify-content-center align-items-center vh-100">
                <div className="d-flex flex-column align-items-center my-5">
                    <span className="text-muted heading-2 mb-1">
                        👀
                    </span>
                    <span className="heading-font text-muted">Нет заказов</span>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="py-3">
                <InputGroup className="d-flex">
                    <Form.Select
                        style={{ flex: '1' }}
                        className="border-rounded-1 flex-1"
                        onChange={(e: any) => handleSearchTypeChange(e.target.value)}
                    >
                        <option value="id">Номер заказа</option>
                        <option value="deliveryReceiver.company.name">Получатель</option>
                        <option value="deliveryReceiver.address.addressString">Адрес получателя</option>
                    </Form.Select>
                    <Form.Control
                        type={searchType === 'orderItemId' ? 'number' : 'text'}
                        style={{ flex: '4' }}
                        className="border-rounded-1 flex-3"
                        placeholder="Введите запрос"
                        onChange={(e: any) => handleSearchInput(e.target.value)}
                    />
                </InputGroup>
            </div>
            <div className="d-flex align-items-center text-primary">
                <span className="heading-font">Всего {result.length}</span>
                <BoxSeam className="mx-1" />
            </div>
            <div>
                {result.map((item, index) => (
                    <OrderListItem
                        key={index}
                        data={item}
                        onClick={() => {
                            navigate('/telegram/courier/view', { state: { orderId: item.id } })
                        }}
                    />
                ))}
            </div>
        </div>
    )
}

interface OrderListProps {
    data: any,
    isLoading?: boolean,
    onAnyChange: () => void,
}

export default OrderList