import { useContext } from "react"

import { AuthContext } from "../providers/AuthProvider"

const useAuth = () => {
    const { authStore } = useContext(AuthContext)
    return authStore
}

export default useAuth
