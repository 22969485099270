import {observer} from "mobx-react-lite";
import {Navigate, Outlet} from "react-router-dom";
import React from "react";

import useAuth from "@/common/hooks/useAuth";
import {Roles} from "@/common/constants/roles";

const RequiresOnboardingRoute = observer(() => {
    const { isNew, requiresOnboard, hasRole} = useAuth();

    if (requiresOnboard() && isNew && hasRole(Roles.Client)) {
        return <Navigate to="/onboard" />;
    }

    return <Outlet />;
});

export default RequiresOnboardingRoute;