export const reportTypeOptions = [
    {
        value: 'cell-load',
        text: 'Отчёт по нагрузке',
    },
    {
        value: 'delivered',
        text: 'Отчет по количеству писем',
    },
    {
        value: 'service-agreements',
        text: 'Отчет по новым договорам',
    },
    {
    	value: 'courier-delivery-dynamics',
    	text: 'Отчет по количеству писем на руках у курьеров'
    }
]

export const dateOptions = [
    {
        value: '*',
        text: 'Дата не выбрана',
    },
    {
        value: 'yesterday',
        text: 'За вчерашний день',
    },
    {
        value: '3day',
        text: 'За три дня',
    },
    {
        value: 'weekly',
        text: 'За неделю',
    },
    {
        value: 'monthly',
        text: 'За месяц',
    },
    {
        value: 'custom',
        text: 'Выбрать период',
    },
]
