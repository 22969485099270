import React from "react";
import { observer } from "mobx-react-lite"
import { Navigate, Outlet } from "react-router-dom";

import useAuth from "@/common/hooks/useAuth"

const PrivateRoute = observer(() => {
  	const auth = useAuth();

	if (!auth.user) {
		auth.signOut()
	}

  	if (!auth.isAuthenticated()) {
		return <Navigate to="/signin" />;
	}

  	return <Outlet />;
});

export default PrivateRoute;