import React, {FC} from 'react';
import {Button, Container, Modal, Row} from "react-bootstrap";
import DefaultButton from "@/components/buttons/DefaultButton";
import {Courier} from "@common/models/courier";

interface CourierDeleteModalProps {
    courierItem?: Courier,
    onDelete: () => void,
    setShowModal: (value: boolean) => void,
    setError: (e: any) => void,
}

const CourierDeleteModal: FC<CourierDeleteModalProps> = ({courierItem, onDelete, setShowModal, setError}) => {
    const handleDelete = async () => {
        try {
            onDelete()
            setShowModal(false)
        } catch (e) {
            setError(e)
        }
    }
    return (
        <div>
            <Modal show={true} dialogClassName="">
                <Container fluid={true}>
                    <Modal.Header className="d-flex justify-content-between">
                        Вы действительно хотите удалить курьера, {courierItem.firstName} {courierItem.lastName} ?
                        <Button className="mr-5" variant="light" onClick={() => setShowModal(false)}>
                            X
                        </Button>
                    </Modal.Header>
                    <Modal.Body className="d-flex justify-content-center">
                        <Row className="w-75 d-flex justify-content-between flex-nowrap">
                            <DefaultButton className={"w-25"} variant={"primary"} onClick={handleDelete}>Да</DefaultButton>
                            <DefaultButton className={"w-25"} variant={"danger"} onClick={() => setShowModal(false)}>Нет</DefaultButton>
                        </Row>
                    </Modal.Body>
                </Container>
            </Modal>
        </div>
    );
};

export default CourierDeleteModal;