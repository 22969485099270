import {FC, memo, ReactNode} from 'react';
import {Field, ErrorMessage as Error} from "formik";
import './FormikInput.css'
import {checkIfEmpty} from "@/common/utils/utils";

interface FormikInputProps {
    id: string,
    label: string,
    name: string,
    placeholder?: string,
    labelOnly?: boolean,
    children?: ReactNode,
    className?: string,
    required?: boolean,
    [options: string]: any
}

const FormikInput: FC<FormikInputProps> = ({id, label, name, labelOnly = false, onChange, placeholder = '', children, className, required = false, ...rest}) => {
    return (
        <div className={`d-flex flex-column ${checkIfEmpty(className)}`}>
            <label htmlFor="id" className="formik-label">
                {label}
                {required && <span className="text-danger">*</span>}
            </label>
            <Field className={`formik-input ${labelOnly && "d-none"} ${checkIfEmpty(className)} `} name={name} id={id} placeholder={placeholder} {...rest} >
                {children}
            </Field>
            <Error name={name}>
                {(error) => 
                    <span className="formik-error">{error}</span>}
            </Error>
        </div>
    );
};

export default memo(FormikInput);