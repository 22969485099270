import React from 'react'
import { Badge } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Formats } from '@/common/constants/dateFormat'
import { convertToTimeZone } from '@/common/utils/utils'

const OrderListItem = ({ data, onClick }: OrderCardProps) => {
    const { t } = useTranslation()

    if (!data) {
        return
    }

    return (
        <div className="position-relative border border-rounded-2 d-flex flex-column bg-white my-3 p-3" onClick={onClick}>
            <div className="position-absolute" style={{ top: '-12px' }}>
                <Badge className="border" pill>
                    <span className="ms-auto heading-badge">{convertToTimeZone(data.createDate, false, Formats.DATE_DMY_TIME)}</span>
                </Badge>
            </div>
            <div className="d-flex heading-font">
                <span>{t(`orderStatus.${data.status}`)}</span>
            </div>
            <div>
                <div className="d-flex">
                    <span>№{data.id}</span>
                    <span>{', ' + data.deliveryReceiver?.company?.name}</span>
                </div>
                <div>
                    <span>{data.deliveryReceiver?.address?.addressString}</span>
                </div>
            </div>
        </div>
    )
}

interface OrderCardProps {
    data: any,
    onClick: () => void,
}

export default OrderListItem