import React from 'react'
import { Spinner } from 'react-bootstrap'
import { CupHotFill, DatabaseExclamation } from 'react-bootstrap-icons'
import CouriersContentTable from './CouriersContentTable'

const CouriersView = ({ data, isLoading, error }: CouriersViewProps) => {

    if (isLoading) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <Spinner />
                <span className="heading-2 my-3">Получение курьеров...</span>
            </div>
        )
    }

    if (error) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <div className="d-flex flex-column align-items-center">
                    <span className="text-danger heading-2 mb-2">
                        <DatabaseExclamation />
                    </span>
                    <span className="heading-2 text-danger">Упс... что-то пошло не так</span>
                </div>
                <span className="text-danger my-3">{error.message}</span>
            </div>
        )
    }

    if (!data || (data && data.length < 1)) {
        return (
            <div className="border-dotted border-rounded-2 d-flex justify-content-center align-items-center h-100">
                <div className="d-flex flex-column align-items-center">
                    <span className="text-muted heading-2 mb-2">
                        <CupHotFill />
                    </span>
                    <span className="heading-2 text-muted">Нет курьеров</span>
                </div>
            </div>
        )
    }

    return (
        <CouriersContentTable
            data={data}
        />
    )
}

interface CouriersViewProps {
    data: any[],
    isLoading: boolean,
    error?: any,
}

export default CouriersView