import { ReportCourierDeliveryDynamics } from '@/common/models/report'
import React from 'react'
import { Table } from 'react-bootstrap'

const ReportCourierDeliveryDynamicsTable = ({ data }: ReportCourierDeliveryDynamicsTableProps) => {

	data.data.map(row => (row.deliveriesPerDate.sort((a,b) => a.date.localeCompare(b.date))))

	const tableHeaders = data.data[0]?.deliveriesPerDate.map(deliveryDate =>
                <th>{deliveryDate.date}</th>)

        return (
            <div>
                <Table bordered striped size="sm">
                    <thead>
                        <tr>
                            <th>Имя</th>
                            {tableHeaders}
                        </tr>
                </thead>
                <tbody>
                    {data.data.map(row => (
                        <tr>
                            <td>{row.firstName + ' ' + row.lastName}</td>
                            {row.deliveriesPerDate.map(deliveryCount =>
                                             <td>{deliveryCount.count}</td>)}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

interface ReportCourierDeliveryDynamicsTableProps {
    data: ReportCourierDeliveryDynamics,
}

export default ReportCourierDeliveryDynamicsTable