import { ReportServiceAgreements } from '@/common/models/report'
import React from 'react'
import { Table } from 'react-bootstrap'

const ReportServiceAgreementsTable = ({ data }: ReportServiceAgreementsTableProps) => {

    return (
        <div>
            <Table bordered striped size="sm">
                <thead>
                    <tr>
                        <th>Название компании</th>
                        <th>Дата заключения договора</th>
                        <th>ID договора</th>
                        <th>Дата начала услуги</th>
                        <th>Дата окончания услуги</th>
                        <th>Статус договора</th>
                        <th>Примечания</th>
                    </tr>
                </thead>
                <tbody>
                    {data.rows.map(row => (
                        <tr>
                            <td>{row.companyName}</td>
                            <td>{row.createDate}</td>
                            <td>{row.id}</td>
                            <td>{row.agreementDate}</td>
                            <td>{row.expirationDate}</td>
                            <td>
                                <span className={row.isActive ? 'text-success' : 'text-danger'}>{row.isActive ? 'Активен' : 'Не активен'}</span>
                            </td>
                            <td>{row.comments}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

interface ReportServiceAgreementsTableProps {
    data: ReportServiceAgreements,
}

export default ReportServiceAgreementsTable