import { ReportDeliveredOrderItemCount } from '@/common/models/report'
import React from 'react'
import { Table } from 'react-bootstrap'

const ReportDeliveredTable = ({ data }: ReportDeliveredTableProps) => {
    return (
        <div>
            <Table bordered striped size="sm">
                <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Общее количество писем</th>
                        <th>Количество доставленных</th>
                        <th>Количество не доставленных</th>
                    </tr>
                </thead>
                <tbody>
                    {data.data.map(row => (
                        <tr>
                            <td>{row.date}</td>
                            <td>{row.totalCount}</td>
                            <td>{row.deliveredParcelCount}</td>
                            <td>{row.nonDeliveredParcelCount}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

interface ReportDeliveredTableProps {
    data: ReportDeliveredOrderItemCount,
}

export default ReportDeliveredTable