import {useCallback, useRef} from "react";

export default function useDebounce(callback, delay: number) {
    const timer = useRef(null)

    const debouncingCallback = useCallback((...args) => {
        if(timer.current) {
            clearTimeout(timer.current)
        }

        timer.current = setTimeout(() =>{
            callback(...args)
        }, delay)
    }, [callback, delay])

    return debouncingCallback;
}