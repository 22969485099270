
export const deliveryTypeOptions = [
    {
        value: '-',
        text: '-', 
    },
    {
        value: 'STANDARD',
        text: 'Обычный',
    },
    {
        value: 'URGENT',
        text: 'Срочный', 
    },
]

export const parcelTypeOptions = [
    {
        value: '-',
        text: '-', 
    },
    {
        value: 'ENVELOPE',
        text: 'Корреспонденция', 
    },
    {
        value: 'SMALL_PACKAGE',
        text: 'Мелкие', 
    },
    {
        value: 'LARGE_PACKAGE',
        text: 'Крупногабаритные', 
    },
]